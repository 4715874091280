<!--
@Author: Calvin Green <calvin>
@Date:   2022-02-02T10:45:30+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2022-02-02T11:27:35+02:00
@Copyright: Diversitude 2021
-->

<template>
    <b-overlay :show="is_loading || is_building_table" >
        <div class="WebixTableComponent" :style="setMinHeight">        
        <!-- <b-button size="sm" @click="debugMethod()">Debugg</b-button> -->
        <div :ref="getTableRefName" v-if="show_table" :class="viewModeClass"> <!-- @mouseleave="closeWebixEditor" < removed due to isses with selecting a date from picker(triggerd close element) -->
            <div class="sub-heading-text mt-0 mb-0 d-flex">
                <div v-if="!noHeader" class="pt-1 align-items-center flex-grow-1 text-truncate">
                    <!-- any text in this slot will be truncated to fit the available space -->
                    <slot name="title"></slot>
                </div>

                <div v-else class="pt-1 align-items-center flex-grow-1 text-truncate">
                    <slot name="title"></slot>
                </div>

                <span class="d-inline-flex ms-auto" style="font-weight: normal;">
                    <slot name="buttons"></slot><!-- add optional extra buttons in front of the actions buttons-->
                    <b-button @click="saveTable" size="sm" variant="success" v-if="changes_made_flag" right class="me-2 dropdown-menu-end">
                        <font-awesome-icon class="fa-fw me-1"  icon="fa-save"></font-awesome-icon>
                        Save Changes
                    </b-button>

                    <b-button size="sm" @click="showDiscardPrompt()" variant="danger" v-if="changes_made_flag" class="me-2">
                        <font-awesome-icon class="fa-fw me-1"  icon="fa-trash"></font-awesome-icon>
                        Discard Changes
                    </b-button>
                    
                    <span v-show="enabled_batch_copy_flag ">
                        <b-button @click="batchCopyItems" size="sm" variant="primary" v-if="batch_copy_items.length > 0" right class="me-2 dropdown-menu-end">
                            {{ getBatchCopyButtonText() }}
                        </b-button>
                        <b-button @click="resetBatchClipboard" size="sm" variant="danger" v-if="first_batch_copy_item != null" right class="me-2 dropdown-menu-end">
                            Reset Batch Clipboard
                        </b-button>
                    </span>

                    <span v-show="enabled_duplicate_copy_flag ">
                        <b-button @click="duplicateItems" size="sm" variant="primary" v-if="items_to_duplicate.length > 0" right class="me-2 dropdown-menu-end">
                            Copy Items ({{items_to_duplicate.length}})
                        </b-button>
                        <!--<b-button @click="resetBatchClipboard" size="sm" variant="danger" v-if="first_batch_copy_item != null" right class="me-2 dropdown-menu-end">
                            <strong>Reset Batch Clipboard</strong>
                        </b-button>-->
                    </span>

                    <b-button @click="deleteRows" size="sm" variant="danger" v-if="delete_array.length > 0" right class="me-2 dropdown-menu-end">
                        <strong>Delete Selected Rows</strong>
                    </b-button>

                    <!-- copy btn -->
                    <b-button size="sm" variant="success" v-if="has_copy_items_selected > 0" right class="me-2">
                        Rows selected: {{has_copy_items_selected}}
                    </b-button>

                    <span v-if="hasDuplicationData" class="me-2">
                        <b-dropdown menu-class="dropdown-menu-end" size="sm" variant="success" right>
                            <template #button-content>
                                Copied Items
                                &nbsp;
                                <i class="fas fa-clipboard" />
                            </template>
                            <b-dropdown-header>{{duplicationClipboardDataLength}} row(s) are ready to be pasted with their datasets</b-dropdown-header>
                            <b-dropdown-item @click="insertDuplicatedItems()" variant="success">
                                Paste items into table
                            </b-dropdown-item>
                            <b-dropdown-item @click="clearDuplicationClipboard()">Clear Duplication Clipboard</b-dropdown-item>
                        </b-dropdown>
                    </span>
                    <span v-if="hasBatchClipboardData" class="me-2">
                        <b-dropdown menu-class="dropdown-menu-end" size="sm" variant="success" right>
                            <template #button-content>
                                Batch
                                &nbsp;
                                <i class="fas fa-clipboard" />
                            </template>
                            <b-dropdown-header>{{batchClipboardDataLength}} row(s) copied to this tables clipboard</b-dropdown-header>
                            <b-dropdown-item @click="clearBatchClipboard()">Clear clipboard</b-dropdown-item>
                        </b-dropdown>
                    </span>
                    <span v-if="hasClipboardData" class="me-2">
                            <b-dropdown menu-class="dropdown-menu-end" size="sm" variant="success" right>
                                <template #button-content>
                                    <i class="fas fa-clipboard" />
                                </template>
                                <b-dropdown-header>
                                        Row copied to clipboard
                                </b-dropdown-header>
                                <b-dropdown-item @click="insertIntoClipboard({uid: uid,data: null})">
                                    Clear Clipboard
                                </b-dropdown-item>
                            </b-dropdown>
                            
                        </span>
                    <FilterComponent v-if="false" :table_uid="uid"/>

                    <span class="table-actions-btn-group btn-group">
                        <span v-show="hide_action_buttons == false" class="table-actions-btn-group btn-group">
                            <slot name="extra-table-actions"></slot>
                            <b-button v-if="enable_pretty_mode" ref="cardViewButton" @click="showCardView()" size="sm" class="no-focus" :variant="pretty_mode  ? 'info':'outline-light'" v-b-tooltip.hover.bottomright title="Card View">
                                    <font-awesome-icon class="fa-fw" icon="fa-solid  fa-window-maximize" />
                            </b-button>
                            <b-button ref="viewButton" @click="setMode('view')" size="sm" v-if="canShow('view')" class="no-focus" :variant="pretty_mode == false && table_mode ==='view' ? 'info':'outline-light'" v-b-tooltip.hover.bottomright title="Table View">
                                    <font-awesome-icon class="fa-fw" icon="fa-solid  fa-table" />
                            </b-button>
                            <b-button v-show="false" :disabled="disable_action_buttons" ref="editButton" @click="setMode('edit')" size="sm" v-if="canShow('edit')" class="no-focus" :variant="pretty_mode == false && table_mode ==='edit' ? 'primary':'outline-light'" v-b-tooltip.hover.bottomright title="Edit Table">
                                    <font-awesome-icon class="fa-fw" icon="fa-solid  fa-pen" />
                            </b-button>
                            <b-button v-show="false" ref="deleteButton" @click="setMode('delete')" size="sm" v-if="canShow('delete')" class="no-focus" :variant="pretty_mode == false && table_mode ==='delete' ? 'danger':'outline-light'" v-b-tooltip.hover.bottomright title="Delete">
                                    <font-awesome-icon class="fa-fw" icon="fa-solid  fa-trash" />
                            </b-button>

                            <b-button  v-if="enable_pretty_mode"  ref="expandButton" @click="expandCardDetails()" size="sm" class="no-focus" :variant="card_expanded ? 'outline-primary' : 'outline-light'" v-b-tooltip.hover.bottomright title="Expand Card Details">
                                <font-awesome-icon class="fa-fw" :icon="card_expanded ? 'fa-caret-up' : 'fa-caret-down'" />
                            </b-button>
                            <!-- <b-button :disabled="data.length == 0" ref="searchButton" @click="toggleSearch()" size="sm" v-if="has_header_filters" class="no-focus" :variant="canShowHeaderFilters ? 'success':'outline-light'" v-b-tooltip.hover.bottomright title="Search">
                                    <font-awesome-icon icon="fa-solid  fa-search" />
                            </b-button> -->
                            
                            
                        </span>
                        <span v-show="hide_action_buttons == false" class="table-actions-btn-group btn-group">
                            <b-button :disabled="data.length == 0" ref="searchButton" @click="toggleSearch()" size="sm" v-if="has_header_filters" class="no-focus" :variant="canShowHeaderFilters ? 'success':'outline-light'" v-b-tooltip.hover.bottomright title="Search">
                                    <font-awesome-icon icon="fa-solid  fa-search" />
                            </b-button>
                            <b-dropdown :disabled="disable_action_buttons" menu-class="dropdown-menu-end" no-caret class="no-focus" size="sm" variant="outline-light">
                                <template #button-content>
                                    <font-awesome-icon icon="fa-solid  fa-ellipsis-h" />
                                </template>
                                <!-- //* show mode related buttons for the table -->
                                <b-dropdown-item v-if="enable_pretty_mode && canShow('view')" @click="showCardView()" class="text-primary">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-address-card" />
                                    Card View
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canShow('view')" @click="setMode('view')" class="text-primary">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-table" />
                                    Table Mode
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canShow('tree')" @click="setMode('view')" class="text-primary">
                                    <font-awesome-icon :icon="['fas', 'sitemap']" />
                                    View Mode
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canShow('edit')" @click="setMode('edit')" class="text-info">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-pen" />
                                    Edit Mode
                                </b-dropdown-item>
                                <b-dropdown-item v-if="canShow('delete')" @click="setMode('delete')" class="text-danger">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-trash" />
                                    Delete Mode
                                </b-dropdown-item>
                                <b-dropdown-item @click="expandCardDetails()" class="text-primary">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-indent" />
                                    Expand 
                                </b-dropdown-item>
                                <b-dropdown-item v-if="guideline_key != false" @click="handleShowGuideline()" class="text-primary">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid fa-question-circle" />
                                    View Guideline 
                                </b-dropdown-item>
                                

                                <!-- slot for extra content options -->
                                <slot name="extra-items"></slot> <!-- add extra button content to the dropdown menu -->
                                <hr  class="p-0 m-0" v-if="isDevEnv()">
                                <b-dropdown-item v-if="isDevEnv()" @click="debugAction()" class="text-danger">
                                    Debug Action
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>

                        <span v-show="hide_action_buttons && guideline_key" class="table-actions-btn-group btn-group">
                            <b-dropdown :disabled="disable_action_buttons" menu-class="dropdown-menu-end" no-caret class="no-focus" size="sm" variant="outline-light">
                                <template #button-content>
                                    <font-awesome-icon icon="fa-solid  fa-ellipsis-h" />
                                </template>
                                <!-- //* show mode related buttons for the table -->

                                <b-dropdown-item v-if="guideline_key != false" @click="handleShowGuideline()" class="text-primary">
                                    <font-awesome-icon class="fa-fw me-1" icon="fa-solid fa-question-circle" />
                                    View Guideline 
                                </b-dropdown-item>
                                

                                <!-- slot for extra content options -->
                                <slot name="extra-items"></slot> <!-- add extra button content to the dropdown menu -->
                                <hr  class="p-0 m-0" v-if="isDevEnv()">
                                <b-dropdown-item v-if="isDevEnv()" @click="debugAction()" class="text-danger">
                                    Debug Action
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </span>
                </span>
            </div>

            <b-alert class="mb-2" :show="enabled_batch_copy_flag" variant="info">
                Only root items OR items with the same parent can be selected for a batch copy
            </b-alert>
            <b-alert class="mb-2" :show="enabled_duplicate_copy_flag" variant="primary">
                Select the graphs that will be copied with their datasets
            </b-alert>
            <!-- pretty container -->

            <!-- outer v-if for enabling the feature-->
            <div class="mt-1" v-if="enable_pretty_mode && hasExtraViewSlot == false">
                <!-- <b-form-input v-show="canShowHeaderFilters" autocomplete="never" size="sm" id="search-bar" placeholder="Search..." class="mb-2" v-model="search_text"></b-form-input> -->
                <!-- inner v-show for toggeling visibility -->
                <TileContainer
                    v-show="pretty_mode"
                    :card_data="card_data"
                    :has_nested_data="treetable"
                    :webix_uid="uid"
                    :inner_card_component_name="inner_card_component_name"
                    :card_title_prefix="card_title_prefix"
                    :badge_class="badge_class"
                    @showNotesModal="showNoteModal"
                    @resetSelection="emitRowSelection({id: -1})"
                >
                </TileContainer>    <!-- v-show="pretty_mode" -->
            </div>
            
            <!-- webix table -->
            <div class="mt-1" v-show="table_mode != 'other' && canShowTable && pretty_mode == false">
                <webix-ui  :class="hideScrollClass" :config="ui" v-model="table_data"></webix-ui>
                <div v-show="canShowPager" :id="uid + 'pager'" style="width: 100%;"></div>
            </div>

            <div v-if="canShowTable == false">
                <slot name="view-mode">
                    
                </slot>
            </div>

            <div>
                <slot name="extra-view">
                    
                </slot>
            </div>

            <!-- //* WIP - validation errors -->
            <div v-if="canShowErrors" class="error-list">
                <span v-for="(error, idx) in error_messages" :key="idx">
                    <span v-if="idx != 0">, </span>{{error}}
                </span>
            </div>
        </div>
        <b-alert class="m-0 mt-1 fix-z-index" :show="canShowHasNoDataAlert">
                <font-awesome-icon class="fa-fw" icon="fa-exclamation-circle" />
            Table has no data.
        </b-alert>

        <NoteModal v-if="show_note_modal" :note_data="note_modal_payload" @save="handleNoteSave" />
    </div>
    </b-overlay>
</template>

<script>
import {webixTable, webixTableState, adminComputed, levelComputed, settingsComputed, guidelineMethods,} from "@/state/helpers.js";
import FilterComponent from '@/components/widgets/table/tableFilter.vue'
import Swal from 'sweetalert2'
import _ from 'lodash';
import tableHelpers from '@/mixins/tableHelpers.js';
import TileContainer from '@/components/objectives/components/tiles/tileContainer.vue'
import NoteModal from './noteModalNew.vue'
export default {
    mixins:[ tableHelpers ],
    components:{
        FilterComponent,
        TileContainer,
        NoteModal
    },
    props:{
        allow_duplication_feature:{
            //* allow table to support the ability to duplicate rows with their datasets
            //* used for kpi, kr and metric graphs
            type: Boolean,
            default: false,
        },
        active_id:{//* get the row that should be active from the parent component
            default: -1,
        },
        button_methods:{
            default: () => { return false }
        },
        badge_class:{
            default: () => { return ' '}
        },
        
        columns:{//* array of column configuration objects
            required: true,
        },
        card_title_prefix:{
            required: false,
            default: '',
        },
        simple_columns:{
            type: Array,
            default: function() {
                return null;
            }
            //default: () => { return []; }
        },

        //specify child tables (aka tables that load data based on the current table)
        //* can be single string or string array
        child_uid:{
            default: false,
        },
        context_menu_items:{
            //*! ONLY SUPPORTS THE FOLLOWING ACTIONS
            //* ['View', 'Edit', 'Delete',] -> Drag is depreciated    
            default: () => { return [] }
        },
        data:{//* array of table rows
            required: true,
        },
        default_sort_column:{
            default: false,
        },
        delete_requirement:{
            default: () => { return null; }
        },
        add_new_rows_permission:{
            //* provide the table with a permission that allows the user to edit new/empty rows
            default: () => { return null }
        },
        edit_permissions:{
            //* provide the table with the names of the user or manager permission required to edit the table
            default: () => {
                return {
                    // generic placeholder that will return false
                    manager: 'na',
                    user: 'na'
                }
            }
        },
        enforce_structure_checks:{
            //* should the table check for manager/user permissions -> NB also requires the edit_permissions prop
            type: Boolean,
            default: false,
        },
        //* flag to save the row id to a vuex store when opening an editor in a cell
        enable_editor_id_store:{
            type: Boolean,
            default: false
            //! need to find a better solution.. 
        },

        //* Render a filter toggle action button if the table has been configured 
        //* to render { content : "someFilter"} inside headers
        has_header_filters:{
            type: Boolean,
            default: false,
        },
        //* array of column id's that must ignore the
        //* 'only_editable_columns' rule
        ignore_edit_only_rules:{
            type: Array,
            default:function(){
                return [];
            }
        },
        table_modes:{
            required: false,
            default(){
                return ['view', 'edit', 'delete']
            },
        },
        default_table_mode:{
            default(){
                return 'view';
            }
        },
        treetable:{//* Boolean value to set table view as datatable or treetable
            type: Boolean,
            default: false
        },
        is_multiselect:{
            type: Boolean,
            default: false,
        },
        is_loading:{//* show b-overlay spinner over table
            type: Boolean,
            default:function(){
                return false;
            }
        },
        min_height:{
            type: Number,
            default:function(){
                return 0;
            }
        },
        no_empty_rows:{
            //prevent the *5* default empty rows that get inserted in view mode 
            type: Boolean
        },
        no_extra_rows:{
            //TODO fix this --
            type: Boolean,
            default: false,
        },
        hide_clear_column:{
            //* by default a Clear colum gets inserted during edit mode (used to clear the data of a new row -- resets data for that row)
            //* set to true to hide and disable this feature
            default: false,
        },
        only_editable_columns:{
            //* Hide all non editable columns while in edit mode
            type: Boolean,
            default: false
        },
        pager_config:{
            //* enables pager, number = rows per page
            type: Number,
            required: false,
            default:function(){
                return 0;
            },
        },
        paste_rules:{
            //* rules for pasting copied rows
            type: Array,
            required: false,
            default:function(){
                return [];
            },
        },
        column_autoheight_key:{
            //* fires the adjustRowHeight method for the provided column key
            default: false,
        },
        prevent_first_row_select:{
            //* By default tables select the first row (if avaliable)
            type: Boolean,
            default: false,
        },
        prevent_edit_ids:{
            type: Array,
            default:function(){
                return [];
            },
        },
        parent_id_variable_name:{
            // parent id variable used for pasting children in treetables
            type: String,
            default: 'parent_id',

        },
        sticky_columns:{//! IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            //* freeze columns from scrolling (starting from the left)
            //! If using sticky columns > 0 then each column needs to have a width property set
            default: 0
        },
        has_split_column:{
            default: false,
        },
        compact_mode:{
            //* only show columns with the compact flag
            default: false,
        },
        default_values:{
            default:function(){
                return null;
            }
        },
        custom_editor_rules:{
            default:function(){
                return false;
            }
        },
        table_height:{
            default:function(){
                return false;
            }
        },
        re_order:{
            type: Boolean,
            default: false
        },
        required_permissions:{
            type: Array,
            default: () => { return [] },
        },
        
        hide_action_buttons:{
            type:Boolean,
            required: false,
        },
        disable_action_buttons:{
            type: Boolean,
            required: false,
        },
        //* --- CARD HEADER ---
        noHeader:{
            type: Boolean,
            default: false,
        },
        uid:{//* Parent component gives the table a unique id/name
            required: false,
            default(){
                return window.webix.uid();
            }
        },
        use_level_ids_array:{
            //set to true if linked level ids is an array -> will use item.level_ids instead of item.level_id
            type: Boolean,
            default: false,
        },
        validation:{//* an object containing validation rules for columns
            default:function(){
                return {};
            }
        },
        validation_messages:{ //* validation messages with the same key as the column validation rule
            default:function(){
                return {};
            }
        },
        variable_watchers:{
            default:function(){
                return {};
            }
        },

        enable_pretty_mode:{
            type: Boolean,
            default:function(){
                return false;
            }
        },

        inner_card_component_name:{
            default: false,
        },

        guideline_key: {
            default: false,
        }

    },
    data:() => ({
        //TODO - add prop to set default mode eg if only view is needed
        table_mode: 'view',

        pager_position: 0,  

        //* used to save the opened state of treetable views
        last_tree_state: null,

        //* keep track of previous selected id, used to determine if the treestate must be restored;
        oldActiveID: -1,

        //* id of treetable row when right clicked
        right_clicked_id: null,

        context_menu_id: null,

        //* get the last added row - used when using right click to add child row (add the row then select it)
        last_added_row: null,

        block_set_state_event: false,

        show_note_modal:false,
        note_modal_payload:{
            name: '',
            notes: '',
            id: '',
        },

        show_table: false, //* set to true after processing columns/data
        hasMounted: false,

        has_copy_items_selected: 0,

        //* variables emitted to parent component
        changes_made_flag: false, //* track if data was changed in edit mode
        updated_rows: [],
        delete_array:[], //* used to return an array of the items flagged for delete

        webix_resize_event: null, //* assign resize event to var. (var used to destroy event in the beforeDestroy hook)

        last_selected_item: null,


        enabled_batch_copy_flag: false,
        first_batch_copy_item: null,
        batch_copy_items:[],

        enabled_duplicate_copy_flag: false,
        items_to_duplicate: [], //array of row id's that the backend will dupe to the new target

        //* used when ctrl/cmd multiselect is used 
        has_multiple_rows_selected: false,

        //* show search/filter in headers (if configured inside the columns array) - also uses prop -> has_header_filters
        show_datatable_filters: false,
        search_text: '',

        //* boolean to allow the onValidationError event to fire 
        show_error_msg: false,
        error_messages: [],
        //TODO - make computed function
        canShowErrors: true,

        //* loading spinner while table is building ( show loading instead of ui freeze )
        is_building_table: false,

        // track newly added rows when in edit mode -> used to delete the rows
        empty_row_ids: [],

        pretty_mode: false,

        //last_




        ui: { //* Webix config object - data/columns gets populated in the mounted hook
            id: "",//* set unique id from uid prop
            view: "treetable",
            css: "table-component textClass webix_data_border webix_header_border",
            select: "row",
            multiselect: true,
            resizeColumn: {headerOnly:true},
            tooltip:true,
            autoheight: true,
            fillspace: true,
            //editaction: "click",
            editable: false,
            drag:false,
            type:{},
            data:[],
            checkboxRefresh: true,
            onContext:{},
            //preventUpdate: true,
            //scrollY: true,
            responsive: true,
            columns: [],
            scroll: false,
            scheme:{
                $change:function(item){
                    if (item.is_new != undefined){
                        item.$css = "new_row_style";
                    }

                    if(item.is_in_clipboard){
                        item.$css = "is_copied_to_clipboard_style";
                    }

                    if(!item.is_new && !item.is_in_clipboard){
                        item.$css = "";
                    }

                    if(!item.is_new && item.checkbox_selection){
                        item.$css = 'delete_row_style'
                    }
                }
            },
            onClick: {
                //eslint-disable-next-line no-unused-vars
                'unlink_button':function(event, item){
                    //!! DEPRECIATED - delete
                    //eslint-disable-next-line
                    let el = this.$scope.$parent.$parent;

                    el.$emit('onParentUnlink', {id: item.row})
                },
                //eslint-disable-next-line no-unused-vars
                'move-item-up':function(event, item){
                    //eslint-disable-next-line no-unused-vars
                    let el = this.$scope.$parent.$parent;
                    if(el.changes_made_flag){
                        Swal.fire('Please save unsaved changes before reordering')
                    }
                    else{
                        //eslint-disable-next-line
                        let row = this.getItem(item.row);
                        let idx = null;
                        if(el.treetable){
                            idx = this.getBranchIndex(item.row);
                        }
                        else{
                            idx = this.getIndexById(item.row);
                        }
                        idx = idx - 1;//* set as previous index
                        //* only items with a parent id need to have the extra params
                        if(row.$parent != 0){
                            this.move(item.row, idx, this, {parent:row.$parent});
                            el.processOrderArray(row.$parent);
                        }
                        else{
                            this.move(item.row, idx);
                            el.processOrderArray();
                        }
                    }
                    
                },
                //eslint-disable-next-line no-unused-vars
                'move-item-down':function(event, item){
                    //eslint-disable-next-line no-unused-vars
                    let el = this.$scope.$parent.$parent;
                    if(el.changes_made_flag){
                        Swal.fire('Please save unsaved changes before reordering')
                    }
                    else{
                        //eslint-disable-next-line
                        let row = this.getItem(item.row);
                        let idx = null;
                        if(el.treetable){
                            idx = this.getBranchIndex(item.row);
                        }
                        else{
                            idx = this.getIndexById(item.row);
                        }
                        idx = idx + 1;//* set next index
                        //* only items with a parent id need to have the extra params
                        if(row.$parent != 0){
                            this.move(item.row, idx, this, {parent:row.$parent});
                            el.processOrderArray(row.$parent);
                        }
                        else{
                            this.move(item.row, idx);
                            el.processOrderArray();
                        }
                    }
                    
                },
                //eslint-disable-next-line no-unused-vars
                'clear_new_row':function(event, item){
                    //eslint-disable-next-line
                    let el = this.$scope.$parent.$parent;
                    el.$nextTick(()=>{
                        this.editCancel();
                        el.$nextTick(()=>{
                            this.remove(item.row);
                            let new_uid = window.webix.uid()
                            this.add({
                                id: new_uid,
                                is_empty: true,
                            });
                            //track empty row
                            el.empty_row_ids.push(new_uid);
                            this.clearValidation();

                            //get count of is_new rows --> if count == 0 then reset the changes_made_flag
                            let new_rows_count = this.find( obj => {
                                return obj.is_new;
                            })
                            if(new_rows_count == 0){
                                el.changes_made_flag = false;
                            }
                            
                        })
                    })
                    
                }
            },
            on: {
                onSyncApply(){
                    console.log('Sync');
                },
                //eslint-disable-next-line
                onColumnResize:_.debounce(function(id, newWidth, oldWidth, user_action){
                    if(user_action){
                        let el = this.$scope.$parent.$parent;
                        let column_state = this.getState();
                        el.saveColumnWidthAdjustment(column_state.size)
                        el.setEditingState({
                            uid: el.uid,
                            state:{
                                column_widths : column_state.size
                            }
                        })
                    }
                }, 100),
                //eslint-disable-next-line
                onStructureUpdate(id, data, old){
                    let el = this.$scope.$parent.$parent;
                    //el.msg('onStructureUpdate')
                    //el.onDataStructureUpdate(this)
                    el.$nextTick(()=>{
                        el.is_building_table = false;
                        //setTimeout(()=>{
                        //    el.triggerAdjustColumnHeight(3);
                        //}, 500)
                        
                    })
                    
                    
                },
                onAfterLoad(){
                    let el = this.$scope.$parent.$parent;
                    //el.msg('onAfterLoad')
                    //if(el.uid == 'objectivesTable'){
                        el.$nextTick(()=>{
                            el.onDataStructureUpdate(this);
                        })
                    //}
                },
                onAfterFilter() {
                    let el = this.$scope.$parent.$parent;
                    el.$nextTick(()=>{
                        let count = this.getVisibleCount();
                        console.log(count)
                        el.$emit('dataCount', count)
                    })
                },
                onBeforeSelect(selection){
                    //TODO - add prop to configure what columns should block row selection (eg notes column)
                    if(selection && selection.column && selection.column == 'notes'){
                        return false;
                    }
                    let el = this.$scope.$parent.$parent;//* get parent context
                    let valid = true;
                    if(el.table_mode === 'view'){
                        const has_unsaved_changes = el.$hasUnsavedChanges(el.child_uid);
                        if(el.child_uid != false && has_unsaved_changes){
                            return false;
                        }
                        let item = this.getItem(selection.row);
                        if(!item){
                            return false;
                        }
                        if(item.is_empty == undefined){
                            return true;
                        }
                        if(item.is_empty == true){
                            return false;
                        }
                    }
                    else if(el.table_mode == 'delete' || el.table_mode == 'edit'){
                        return false;
                    }
                    return valid;
                },
                onAfterUnSelect(){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    el.$nextTick(()=>{
                        if(el.table_mode === 'view'){
                            if(el.has_multiple_rows_selected && el.is_multiselect){
                                let items = this.getSelectedItem(true);
                                //if(items.length == 1){
                                //    //return the only item from the array
                                //    el.emitRowSelection(items[0]);
                                //    el.has_multiple_rows_selected = false
                                //}
                                //else{
                                    el.emitRowSelection(items);
                                //}
                            }
                        }
                    })
                },
                onAfterSelect(id){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    if(el.table_mode === 'view'){
                        if(el.is_multiselect){ //* is table mustiselect ( has the prop )
                            let items = this.getSelectedItem(true);
                            if(items.length == 1){
                                el.has_multiple_rows_selected = false;
                                //let item = this.getItem(id);
                                el.emitRowSelection(items[0]);
                            }
                            else{
                                el.has_multiple_rows_selected = true;
                                el.emitRowSelection(items);
                            }
                        }
                        else{
                            //* added wait time for parent view to properly render before sending the new row
                            let item = this.getItem(id);
                            el.emitRowSelection(item);
                        }
                        
                    }
                    else if(el.table_mode === 'delete'){
                        let index = _.findIndex(el.delete_array, {id: id});
                            if(index == -1){
                                let item = this.getItem(id);
                                console.log(item);
                            }
                    }
                    //el.last_tree_state = this.getState();
                },
                onAfterAdd(id){
                    let el = this.$scope.$parent.$parent;
                    el.last_added_row = id;
                },
                onAfterEditStop(state, target){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    let id = target.row;
                    let item = this.getItem(id);
                    // flag row if it doesn't exist in db
                    //* check if cell had a valid change
                    if((state.old == undefined || state.old == '') && state.value){
                        if(item.is_empty != undefined){
                            //* flag an empty row as a new row so that the validation rules apply.
                            delete item.is_empty;
                            item['is_new'] = true;
                            if(el.default_values != null){
                                console.log('DEFAULT VALUES ->', el.default_values);
                                el.default_values.forEach( default_item => {
                                    //* only set the default values on items that are empty or null
                                    if(item[default_item.id] == null || item[default_item.id] === ''){
                                        item[default_item.id] = default_item.value;
                                    }
                                })
                            }
                            //*update the row in webix to reflect the changes
                            this.updateItem(id, item);
                            this.refresh();
                        }
                    }
                    
                    //* check if column name matches any function defined as a variable watcher
                    // params called -> (old_value, new_value, item_id, is_item_new)
                    if(el.variable_watchers[target.column] != undefined){
                        const is_new = item.is_new == true
                        el.variable_watchers[target.column](state.value, state.old, item.id, is_new)
                    }
                    //}
                },
                onBeforeEditStart(cell){
                    let el = this.$scope.$parent.$parent;//* get parent context
                    if(el.table_mode == 'view'){
                        //* block editor in view mode
                        return false;
                    }
                    // here we check if the row is blacklisted from being edited
                    if(el.prevent_edit_ids.includes(cell.row)){
                        el.$emit('blockedEditAction', cell.row);
                        return false
                    }
                    //* block edit ability if the duplicate flag is enabled
                    if(el.enabled_duplicate_copy_flag){
                        let rowID = cell.row;
                        let item = this.getItem(rowID);
                        let included_index = el.items_to_duplicate.findIndex( row => row == rowID );
                        let state = included_index == -1 ? 0 : 1
                        if(state == 0){
                            el.insertIntoDuplicationArray(rowID);
                            item['is_in_clipboard'] = true;
                            this.updateItem(rowID, item);
                        }
                        else{
                            el.removeFromDuplicationArray(rowID);
                            item.is_in_clipboard = false;
                            this.updateItem(rowID, item);
                        }
                        return false;
                    }
                    //* block edit ability if the copy flag is enabled
                    if(el.enabled_batch_copy_flag){
                        let rowID = cell.row;
                        //* track the first selected item
                        if(el.first_batch_copy_item == null){
                            let item = this.getItem(rowID);
                            el.first_batch_copy_item = item;
                            el.insertItemIntoClipboardArray(item);
                            item['is_in_clipboard'] = true;
                            this.updateItem(rowID, item);
                        }
                        else{
                            let item = this.getItem(rowID);
                            if(el.first_batch_copy_item.parent_id != item.parent_id){
                                Swal.fire('Only items with the same parent can be selected');
                                return;
                            }
                            let included_index = el.batch_copy_items.findIndex( row => row.id == rowID );
                            // if index is found, then sta
                            let state = included_index == -1 ? 0 : 1
                            if(state == 0){
                                el.insertItemIntoClipboardArray(item);
                                item['is_in_clipboard'] = true;
                                this.updateItem(rowID, item);
                            }
                            else{
                                el.removeItemFromClipboard(item);
                                item.is_in_clipboard = false;
                                this.updateItem(rowID, item);
                                if(el.hasBatchClipboardData == false){
                                    el.first_batch_copy_item = null;
                                }
                            }
                        }
                        return false;
                    }


                    //* if Power User then always allow action
                    if(el.$hasUserRole('Power User')){
                        return true;
                    }

                    let item = this.getItem(cell.row);
                    //* assuming the table has valid perms to go into edit mode
                    if(item.is_empty || item.is_new){
                        //* here we check if there is a permission provided to the table to check if the user can add new rows
                        if(el.add_new_rows_permission == null){
                            // no perm provided -> can add new rows
                            return true;
                        }
                        else if(el.$hasPerm(el.add_new_rows_permission)){
                            // perm privided and user passed the permission check -> can add new rows
                            return true;
                        }
                        else{
                            // user failed perm check -> deny new row
                            
                            el.showToast(
                                "Permission to add new rows not found",
                                "error"
                            )
                            return false;
                        }
                    }

                    if(el.enforce_structure_checks == false){
                        //* table has no level/manager checks, allow the edit action
                        return true;
                    }
                    //* if the above check is false then this next check bust me true to continue
                    //* the table requires either a normal perm (people) or a manager perm (people-manager)
                    let has_editable_perms = el.$hasPerm(el.edit_permissions.manager) || el.$hasPerm(el.edit_permissions.user) ;
                    if(!has_editable_perms){ return false; }
                    
                    let structure_permission = '';
                    if(el.$hasPerm('all-structures')){
                        structure_permission = 'all-structures'
                    }
                    else if(el.$hasPerm('your-structure-only')){
                        structure_permission = 'your-structure-only'
                    }
                    
                    let is_own_item = el.core.id == item.owner_user_id;
                    let is_manager_item = el.isManagerCheck(el.core.id, item.owner_user_id);
                    switch(structure_permission){
                        case 'all-structures':{
                            if(el.edit_permissions.manager && el.$hasPerm(el.edit_permissions.manager)){
                                //* manager check
                                return is_own_item || is_manager_item;
                            }
                            else if(el.edit_permissions.user && el.$hasPerm(el.edit_permissions.user)){
                                //* normal user check
                                return is_own_item;
                            }
                            else{
                                //* no valid perms
                                return false;
                            }
                        }
                        case 'your-structure-only':{
                            let has_owner_access = is_own_item || is_manager_item;
                            let belongs_to_structure = false;
                            if(el.use_level_ids_array == false){
                                belongs_to_structure = _.find(el.core.user_levels, {id: item.level_id}) != undefined ? true : false;
                            }
                            else{
                                belongs_to_structure = el.core.user_levels.find( element => item.level_ids.includes(element.id)) != undefined ? true : false;
                            }
                            //
                            if(el.edit_permissions.manager && el.$hasPerm(el.edit_permissions.manager)){
                                //* manager check -- this is a weird edge case  - probably a permission problem for the user
                                return belongs_to_structure && has_owner_access;
                            }
                            else if(el.edit_permissions.user && el.$hasPerm(el.edit_permissions.user)){
                                //* normal user check
                                return belongs_to_structure && has_owner_access;
                            }
                            else{
                                //* no valid perms
                                return false;
                            }
                        }
                        default:{
                            let has_owner_access = is_own_item || is_manager_item;
                            let belongs_to_structure = _.find(el.core.user_levels, {id: item.level_id}) != undefined ? true : false;
                            if(el.edit_permissions.manager && el.$hasPerm(el.edit_permissions.manager)){
                                //* manager check -- this is a weird edge case  - probably a permission problem for the user
                                return belongs_to_structure && has_owner_access;
                            }
                            else if(el.edit_permissions.user && el.$hasPerm(el.edit_permissions.user)){
                                //* normal user check
                                return belongs_to_structure && has_owner_access;
                            }
                            else{
                                //* no valid perms
                                return false;
                            }
                        }
                    }
                },
                onBeforeEditStop(state){
                    if(state.old == undefined && state.value === ''){
                        this.editCancel();
                        //delete state.value;
                        state.value = undefined
                    }
                },
                onDataUpdate(id){
                    //eslint-disable-next-line
                    var el = this.$scope.$parent.$parent;
                    if(el.table_mode == 'view'){
                        el.restoreColumnSort();
                    }
                    if(el.enabled_batch_copy_flag || el.enabled_duplicate_copy_flag){
                        return;
                    }
                    if(el.table_mode == 'delete'){
                        return true;
                    }

                    //* only handle this event if in edit mode or modal is shown
                    if(el.table_mode == 'edit' || el.show_note_modal){
                        //* flag tracks changes made to the datatable
                        //! only needed for the edit mode 
                        if(el.show_note_modal == false){
                            el.changes_made_flag = true;
                        }
                        
                        el.$emit('onDataUpdate', el.changes_made_flag);
                        //eslint-disable-next-line
                        let empty_row_count = 0;
                        this.eachRow( row => {
                            const rec = this.getItem(row);
                            if(rec.is_empty != undefined){
                                empty_row_count++;
                            }
                        })

                        //* if table is in edit mode, add new empty row if there are no more empty rows left
                        //!  ignore if statement if  -> no_empty_rows prop is added
                        if(!el.no_empty_rows && empty_row_count == 0){
                            let row = {
                                id: `newRow-${window.webix.uid()}`,
                                is_empty: true,
                            }
                            el.empty_row_ids.push(row.id);
                            this.add(row);
                        }

                        //* track updated row id's
                        if(!el.updated_rows.includes(Number(id))){
                            //* only push unique id values
                            el.updated_rows.push(Number(id));
                        }
                        //el.triggerAdjustColumnHeight(5)
                    }
                    
                },
                //eslint-disable-next-line
                onCheck(rowID, colID, state){
                    let el = this.$scope.$parent.$parent;
                    if(el.table_mode === 'delete'){
                        if(state == 1){
                            let index = _.findIndex(el.delete_array, {id: rowID});
                            if(index == -1){
                                el.delete_array.push({id: rowID});
                            }
                        }
                        else{
                            let index = _.findIndex(el.delete_array, {id: rowID});
                            if(index != -1){
                                el.delete_array.splice(index, 1);
                            }
                        }
                    }
                    else{
                        // here we check if the row is blacklisted from being edited
                        if(el.prevent_edit_ids.includes(rowID)){
                            //* get edited row
                            let item = this.getItem(rowID);
                            //* flip state
                            item[colID] = !item[colID];
                            this.updateItem(rowID, item);
                            el.$emit('blockedEditAction', rowID);
                        }
                        el.$emit('onCheck', { rowID: rowID, colID: colID, state: state })
                    }
                },
                //eslint-disable-next-line
                onBeforeDrag:function(context){
                    let el = this.$scope.$parent.$parent;
                    if(el.table_mode === 'view' || el.table_mode === 'edit' || el.table_mode === 'delete'){
                        return false;
                    }
                    let item = this.getItem(context.start);
                    context.html = `<span class="webix_drag_item">${item.name}</span>`;
                },
                //eslint-disable-next-line
                onBeforeContextMenu:function(id, e, node){
                    let el = this.$scope.$parent.$parent;
                    el.right_clicked_id = id;

                    let ctx_menu =  window.webix.$$(el.context_menu_id);
                    if(ctx_menu != undefined){
                        ctx_menu.clearAll();
                        let options = el.getContextMenuItems;
                        ctx_menu.define('data', options);
                        ctx_menu.refresh();
                    }
                },
                onItemDblClick(){
                    let el = this.$scope.$parent.$parent;
                    el.$emit('onDoubleClick',this.getSelectedId())
                },
                //eslint-disable-next-line
                onValidationError:function(id, obj, details){
                    //* parent component sets the show_error_msg to true via allowValidationMessage() (uses a ref that is the same as the table_uid)
                    let el = this.$scope.$parent.$parent;
                    if(el.show_error_msg)
                    {
                        el.show_error_msg = false;
                        el.error_messages = [];
                        //* details = the column keys that failed validation
                        const keys = Object.keys(details);
                        keys.forEach( key => {
                            el.error_messages.push(el.validation_messages[key]);
                        });
                    }
                },
                onAfterClose(){
                    let el = this.$scope.$parent.$parent;
                    //* save opened state to vuex and localStorage
                    if(el.treetable){
                        el.preserveTreeExpandedState();
                    }
                    
                },
                onAfterOpen(){
                    let el = this.$scope.$parent.$parent;
                    //* save opened state to vuex and localStorage
                    if(el.treetable){
                        el.preserveTreeExpandedState();
                    }
                },
            },
            ready(){
                let el = this.$scope.$parent.$parent;
                el.context_menu_id = el.ui.id+'-'+window.webix.uid()
                window.webix.ui({
                    view:'contextmenu',
                    id: el.context_menu_id,
                    //data: el.getContextMenuItems,
                    on:{
                        onBeforeShow(){
                            //* fix ctx menu showing thin line if there are no options to show
                            if(this.getVisibleCount() == 0){
                                this.hide();
                            }
                        },
                        onMenuItemClick: async function(id){
                            //* Same actions that appear in the 3 dot menu
                            switch(id){
                                case 'Delete':{
                                    if(el.changes_made_flag){
                                        el.showDiscardPrompt('delete')
                                    }
                                    break;
                                }
                                case 'Edit':{
                                    el.table_mode = 'edit';
                                    break;
                                }
                                case 'View':{
                                    if(el.changes_made_flag){
                                        el.showDiscardPrompt('view')
                                    }
                                    else{
                                        el.table_mode = 'view';
                                    }
                                    break;
                                }
                                case 'Drag':{
                                    el.$emit('updateViewMode', 'drag');
                                    break;
                                }
                                case 'Copy Row':{
                                    let row = _.cloneDeep(window.webix.$$(el.uid).getItem(el.right_clicked_id));
                                    if(el.enabled_batch_copy_flag){
                                        el.batchCopyItems();
                                    }
                                    else{
                                        //* normal single row copy
                                        if(row.is_empty){
                                            Swal.fire(`Empty row can't be copied`);
                                            break;
                                        }
                                        //* reset row's id to create new item
                                        row.id = `newRow-${window.webix.uid()}`;
                                        row.is_new = true;
                                        row.is_empty = undefined;
                                        let clipbord_params = {
                                            uid: el.uid,
                                            data: row,
                                        }
                                        el.insertIntoClipboard(clipbord_params);
                                    }
                                    break;
                                }
                                case 'Paste Row':{
                                    let target_row = window.webix.$$(el.uid).getItem(el.right_clicked_id);
                                    let is_overwriting_row = false;
                                    if(!target_row.is_empty){
                                        //eslint-disable-next-line no-inner-declarations
                                        async function confirm(){
                                            let prompt_result = await Swal.fire({
                                                title: "Replace data?",
                                                text: "Warning, this row's data will get replaced with the data you are trying to paste",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#34c38f",
                                                cancelButtonColor: "#f46a6a",
                                                confirmButtonText: "Yes",
                                            }).then((result) => {
                                                return result.value;
                                            });
                                            return prompt_result;
                                        }
                                        if(!await confirm()){
                                            break;
                                        }
                                        else{
                                            //* setting this flag as true so that the code can update the parent_id of the pasted row 
                                            is_overwriting_row = true
                                        }
                                        //Swal.fire('Invalid target row, please paste on an empty row');
                                        //break;
                                    }
                                    let clipboard_row = el.clipboard.find( item => {
                                        return item.uid == el.uid;
                                    })
                                    if(clipboard_row){
                                        //! clone to prevent vuex mutation error
                                        let modified_row = _.cloneDeep(clipboard_row);
                                        //* added .row because right_clicked_id was an object :(
                                        modified_row.id = el.right_clicked_id.row;
                                        modified_row['is_new'] = true;
                                        if(modified_row.is_empty){
                                            delete modified_row.is_empty
                                        }

                                        
                                        //modified_row['is_empty'] = undefined;
                                        if(is_overwriting_row){
                                            // Always used - if treetable
                                            modified_row.parent_id = target_row.parent_id;
                                            modified_row.parent = target_row.parent_id;
                                            //modified_row.parent_objective_id = target_row.parent_id;
                                            modified_row['$parent'] = target_row.parent_id;

                                            //* paste rules - for replace rows, don't use the paste default rule
                                            if(el.paste_rules.length > 0){
                                                el.paste_rules.forEach( rule => {
                                                    modified_row[rule.id] = target_row[rule.value];
                                                })
                                            }
                                            // loop through paste rules to apply correct values
                                            //if(target_row.level_id != undefined){
                                            //    modified_row.level_id = target_row.level_id;
                                            //}
                                        }
                                        else{
                                            if(el.paste_rules.length > 0){
                                                el.paste_rules.forEach( rule => {
                                                    if(rule.default){
                                                        console.log('RULE', rule);
                                                        modified_row[rule.id] = rule.default;
                                                    }
                                                })
                                            }
                                        }
                                        window.webix.$$(el.uid).updateItem(el.right_clicked_id, _.cloneDeep(modified_row));
                                        //el.insertIntoClipboard({uid: el.uid,data: null})
                                    }
                                    break;
                                }
                                case 'Paste Row as Child':{
                                    //eslint-disable-next-line
                                    let target_row = window.webix.$$(el.uid).getItem(el.right_clicked_id);
                                    let clipboard_row = el.clipboard.find( item => {
                                        return item.uid == el.uid;
                                    })
                                    if(clipboard_row){
                                        //! clone to prevent vuex mutation error
                                        let modified_row = _.cloneDeep(clipboard_row);
                                        //* added .row because right_clicked_id was an object :(
                                        modified_row.id = window.webix.uid();//el.right_clicked_id.row;
                                        modified_row['is_new'] = true;
                                        modified_row['is_empty'] = undefined;
                                        //if(is_overwriting_row){
                                            modified_row.parent_id = el.right_clicked_id.row;
                                            modified_row.parent = el.right_clicked_id.row;
                                            modified_row.parent_objective_id = el.right_clicked_id.row;
                                        //    modified_row['$parent'] = target_row.parent_id;
                                            if(target_row.level_id != undefined){
                                                modified_row.level_id = target_row.level_id;
                                            }
                                        //}
                                        window.webix.$$(el.uid).data.add(modified_row, 0, el.right_clicked_id.row)
                                        el.changes_made_flag = true;
                                        el.$emit('onDataUpdate', el.changes_made_flag);
                                        //* track updated row id's
                                        if(!el.updated_rows.includes(Number(modified_row.id))){
                                            //* only push unique id values
                                            el.updated_rows.push(Number(modified_row.id));
                                        }
                                        //window.webix.$$(el.uid).updateItem(el.right_clicked_id, modified_row );
                                    }
                                    break;
                                }
                                case 'Batch Paste Rows':{
                                    let table_clipboard = el.batch_clipboard.find( item => {
                                        return item.uid == el.uid;
                                    })
                                    let clipboard_rows = table_clipboard.rows;
                                    // * set the index to insert items
                                    let insert_index = 0;
                                    if(el.data){
                                        //* if table has data, get the index of the first empty row
                                        let first_empty_row = window.webix.$$(el.uid).data.find(
                                            function(object){
                                                return object.is_empty != undefined && object.is_empty == true
                                            },
                                            true // pass true to only return first hit
                                        );
                                        insert_index = window.webix.$$(el.uid).data.getIndexById(first_empty_row.id)
                                    }
                                    if(clipboard_rows){
                                        clipboard_rows.forEach( clipboard_row=>{
                                            //! clone to prevent vuex mutation error
                                            let modified_row = _.cloneDeep(clipboard_row);
                                            modified_row.id = window.webix.uid();//el.right_clicked_id.row;
                                            modified_row['is_new'] = true;
                                            modified_row['is_empty'] = undefined;
                                            modified_row['level_id'] = el.selected_level.id;
                                            window.webix.$$(el.uid).data.add(modified_row, insert_index)
                                            insert_index++; // increase index to insert next row after the last inserted row

                                            //* track updated row id's
                                            if(!el.updated_rows.includes(Number(modified_row.id))){
                                                //* only push unique id values
                                                el.updated_rows.push(Number(modified_row.id));
                                            }
                                        });
                                        el.changes_made_flag = true;
                                        el.$emit('onDataUpdate', el.changes_made_flag);
                                        //window.webix.$$(el.uid).updateItem(el.right_clicked_id, modified_row );
                                    }
                                    break;
                                }
                                case 'Batch Paste Rows as Children':{
                                    //eslint-disable-next-line
                                    let target_row = window.webix.$$(el.uid).getItem(el.right_clicked_id);
                                    if(target_row.is_empty || target_row.is_new){
                                        Swal.fire('Invalid target row for adding children, please ensure that the target row is not empty OR is not new/unsaved');
                                        return;
                                    }
                                    let table_clipboard = el.batch_clipboard.find( item => {
                                        return item.uid == el.uid;
                                    })
                                    let clipboard_rows = table_clipboard.rows;
                                    //let clipboard_rows = el.batch_clipboard.find( item => {
                                    //    return item.uid == el.uid;
                                    //})
                                    if(clipboard_rows){
                                        clipboard_rows.forEach( clipboard_row=>{
                                            //! clone to prevent vuex mutation error
                                            let modified_row = _.cloneDeep(clipboard_row);
                                            //* added .row because right_clicked_id was an object :(
                                            modified_row.id = window.webix.uid();//el.right_clicked_id.row;
                                            modified_row['is_new'] = true;
                                            modified_row['is_empty'] = undefined;
                                            //if(is_overwriting_row){
                                                modified_row.parent_id = el.right_clicked_id.row;
                                                modified_row.parent = el.right_clicked_id.row;
                                                modified_row.parent_objective_id = el.right_clicked_id.row;
                                            //    modified_row['$parent'] = target_row.parent_id;
                                                if(target_row.level_id != undefined){
                                                    modified_row.level_id = target_row.level_id;
                                                }
                                            //}
                                            window.webix.$$(el.uid).data.add(modified_row, 0, el.right_clicked_id.row)

                                            //* track updated row id's
                                            if(!el.updated_rows.includes(Number(modified_row.id))){
                                                //* only push unique id values
                                                el.updated_rows.push(Number(modified_row.id));
                                            }
                                        });
                                        el.changes_made_flag = true;
                                        el.$emit('onDataUpdate', el.changes_made_flag);
                                        //window.webix.$$(el.uid).updateItem(el.right_clicked_id, modified_row );
                                    }
                                    break;
                                }
                                case 'Add Child Row':{
                                    let row = window.webix.$$(el.uid).getItem(el.right_clicked_id);
                                    //* Prevent adding new rows to rows with the 'is_new' flag
                                    if(row.is_new){
                                        Swal.fire('Unable to add a child row to a new unsaved row. Please save and try again.');
                                        return;
                                    }
                                    let new_row = {
                                        is_new: true,
                                        level_id: row.level_id,
                                        parent_objective_id: row.id,
                                        parent_objective_name : row.name,
                                        parent_id: row.id,
                                    };
                                    //* if a table has default_values then add in those fields
                                    el.default_values.forEach( item => {
                                        if(new_row[item.id] == undefined){
                                            new_row[item.id] = item.value
                                        }
                                    })

                                    window.webix.$$(el.uid).add(new_row, 0, el.right_clicked_id);
                                    window.webix.$$(el.uid).open(row.id);
                                    el.$nextTick(()=>{
                                        window.webix.$$(el.uid).select(el.last_added_row);
                                    })

                                    el.changes_made_flag = true;
                                    el.$emit('onDataUpdate', el.changes_made_flag);
                                    break;
                                }
                                case 'Enable Batch Copy':{
                                    // flip boolean state
                                    el.enabled_batch_copy_flag = !el.enabled_batch_copy_flag;
                                    break;
                                }
                                case 'Disable Batch Copy':{
                                    // flip boolean state
                                    el.enabled_batch_copy_flag = !el.enabled_batch_copy_flag;
                                    break;
                                }
                                case 'Enable Graph Dataset Copy':{
                                    // flip boolean state
                                    el.enabled_duplicate_copy_flag = !el.enabled_duplicate_copy_flag;
                                    break;
                                }
                                case 'Disable Graph Dataset Copy':{
                                    // flip boolean state
                                    el.enabled_duplicate_copy_flag = !el.enabled_duplicate_copy_flag;
                                    break;
                                }
                            }
                        }
                    }
                }).attachTo(this);
                el.hasMounted = true;
                el.$nextTick(()=>{
                    el.restoreSelection();
                })
            }
        },
    }),
    computed:{
        ...webixTableState,
        ...adminComputed,
        ...levelComputed,
        ...settingsComputed,
        card_expanded(){
            return this.expanded_card_ids.includes(this.uid);
            //return false;
        },
        setMinHeight(){
            //* check if component has a min height prop and set that in px
            if(this.min_height > 0){
                let can_set_height = this.data.length == 0
                return can_set_height ? `min-height: ${this.min_height}px;` : '';
            }
            else{
                return '';
            }
        },
        canShowTable(){
            if(!this.$slots['view-mode']){
                if(this.uid == 'objectivesTableGOAL'){
                    return 'view-mode TRUE'
                }
                return true;
            }

            else{
                if(this.table_mode == 'view'){
                    if(this.uid == 'objectivesTableGOAL'){
                        return 'FALSE'
                    }
                    return false;
                }
                else{
                    if(this.uid == 'objectivesTableGOAL'){
                        return 'TRUE'
                    }
                    return true;
                }
            }
        },
        hasExtraViewSlot(){
            
            if(this.$slots['extra-view'] == undefined){ return false; }

            let slot_index = this.$slots['extra-view'].findIndex( slot => { return slot.tag != undefined });

            return slot_index > -1;
        },
        hideScrollClass(){
            if(this.data.length == 0 && this.table_mode == 'view'){
                return 'hide-scroll-bar'
            }
            else{
                return '';
            }
        },
        isCompanyLevel(){
            //* returns true when the current level id matches the company level id for the current level
            return this.company_id == this.current_level_id;
        },
        canShowHasNoDataAlert(){
            let currently_loading = this.is_loading || this.is_building_table;
            if(this.table_mode != 'edit' && currently_loading == false && this.data.length == 0 ){
                return true;
            }
            else{
                return false;
            }
        },
        canEditCheck(){
            let table_id = _.findKey(this.table_states, { is_editing_table : true })
            console.log(this.table_states, table_id)
            if(table_id == undefined){
                return false
            }
            else{
                return true
            }
        },
        getTableRefName(){
            return `table-${this.uid}`;
        },
        hasDuplicationData(){
            //* check duplication_clipboard if there is data for the current tables uid
            let index = this.duplicate_clipboard.findIndex( item => {
                return item.uid == this.uid;
            })
            return index != -1 ? true : false;
        },
        hasClipboardData(){
            //* check clipboard if there is data for the current tables uid
            let index = this.clipboard.findIndex( item => {
                return item.uid == this.uid;
            })
            return index != -1 ? true : false;
        },
        hasBatchClipboardData(){
            //* check clipboard if there is data for the current tables uid
            let index = this.batch_clipboard.findIndex( item => {
                return item.uid == this.uid;
            })
            return index != -1 ? true : false;
        },
        batchClipboardDataLength(){
            let clipboard_index = this.batch_clipboard.findIndex( item => {
                return item.uid == this.uid;
            })
            return clipboard_index != -1 ? this.batch_clipboard[clipboard_index].rows.length : '';
        },
        duplicationClipboardDataLength(){
            let dupe_index = this.duplicate_clipboard.findIndex( item => {
                return item.uid == this.uid;
            })
            return dupe_index != -1 ? this.duplicate_clipboard[dupe_index].rows.length : '';
        },
        getContextMenuItems(){
            let arr = [];
            if(this.table_mode === 'edit'){
                if(this.treetable == true){
                    //* only treetables can have child rows
                    arr.push('Add Child Row');
                }
                arr.push('Copy Row')
                if(this.enabled_batch_copy_flag == false){
                    arr.push('Enable Batch Copy')
                }
                else{
                    arr.push('Disable Batch Copy')
                }
                if(this.allow_duplication_feature){
                    if(this.enabled_duplicate_copy_flag == false){
                        arr.push('Enable Graph Dataset Copy')
                    }
                    else{
                        arr.push('Duplicate Data Into Graph');
                        arr.push('Disable Graph Dataset Copy');
                    }
                }
                
                if(this.hasClipboardData){
                    arr.push('Paste Row')
                    //TODO - add back the paste child row functionality 
                    if(this.treetable == true){
                        arr.push('Paste Row as Child');
                    }
                }
                if(this.hasBatchClipboardData){
                    if(this.treetable == true){
                        arr.push('Batch Paste Rows')
                        arr.push('Batch Paste Rows as Children');
                    }
                    else{
                        arr.push('Batch Paste Rows')
                    }
                }
                return arr;
                //* old
                //* return merged array
                //return [...arr, ...this.context_menu_items];
            }
            else{
                return [];
                //return this.context_menu_items;
            }
        },
        getView(){//! no idea what this is for??
            if(window.webix.$$(this.uid) != undefined){
                return window.webix.$$(this.uid).config.view;
            }
            else{
                return '-'
            }
        },
        card_data(){
            if(this.search_text != ''){
                return this.data.filter((item) =>
                    item.name.toLowerCase().includes(this.search_text.toLowerCase())
                );
            }
            else{
                return this.data;
            }
        },
        table_data(){
            //* apply fitering/mutations to the table data;
            let data = _.cloneDeep(this.data);
            if(this.table_mode === 'edit'){
                if(this.hasPagerProp){
                    let remainder = data.length % this.pager_config;
                    //* get available rows to fill rest of the rows as empty
                    let rows_to_add = (this.pager_config - remainder);
                    if(this.hasPagerProp){
                        //* add extra rows to the end of the table using the same no of rows used as the pager config (eg if pager shows 10 rows, add 10)
                        rows_to_add += this.pager_config;
                    }

                    for(let i = 0; i < rows_to_add; i++){
                        data.push({
                            id: window.webix.uid(),
                            is_empty: true,
                        })
                    }
                }
            }
            return data;
        },
        viewModeClass(){
            switch(this.table_mode){
                case 'view':{
                    return 'tableViewMode'
                }
                case 'edit':{
                    return 'tableEditMode'
                }
                case 'delete':{
                    return 'tableDeleteMode'
                }
                case 'drag':{
                    return 'tableDragMode'
                }
                default :{
                    return '';
                }
            }
        },
        canShowPager(){
            return this.hasPagerProp && this.table_data.length > this.pager_config;
        },
        hasPagerProp(){
            //* pager config returns the number or roes to display for the pager
            if(this.pager_config > 0 ){
                return true;
            }
            else{
                return false;
            }
        },
        canShowHeaderFilters(){
            if(this.table_states[this.uid] == undefined){
                return false;
            }
            else{
                return this.table_states[this.uid].show_datatable_filters;
            }
        },
    },
    watch:{
        search_text(){
            let is_table = ['view', 'edit', 'delete'].includes(this.table_mode);
            if(is_table == false){
                let table = this.getTable();
                if(table){
                    //table.filter(this.search_text)
                    var column = table.getColumnConfig("name");
                    this.setTimeout(()=>{
                    column.filterInput.setValue(this.search_text);
                        
                    }, 1000)
                    // Set the filter text
                }
            }

        },
        hide_action_buttons(new_v){
            console.log('hide_action_buttons Look', this.uid, new_v);
        },
        pretty_mode(new_state){
            if(new_state){
                this.scrollToCard();
            }
        },
        is_left_menu_collapsed(){
            let table = this.getTable();
            if(table){
                this.$nextTick(()=>{
                    table.refresh();
                })
            }
        },
        //* set table view type based on the table_mode prop
        table_mode(newValue, oldValue){
            //* reset tracking variables
            this.resetTableEditState();

            //* set table to edit/view/delete mode
            this.setTableMode(newValue, oldValue);
            
            this.$emit('updateViewMode', this.table_mode)
        },
        active_id(newValue, oldValue){
            // sync pretty cards with table (selected item)
            
            if(this.hasMounted && window.webix.$$(this.uid) != undefined){
                if(Array.isArray(this.active_id)){
                    console.log('Multi row selection')
                }
                else{
                    this.oldActiveID = oldValue;
                    if(newValue == -1){
                        let table = window.webix.$$(this.uid);
                        if(table != undefined){
                            window.webix.$$(this.uid).clearSelection();
                        }
                    }
                    else{
                        this.$nextTick(()=>{
                            this.restoreSelection();
                        })
                    }
                }
            }
            this.updateActiveCardID({uid: this.uid, item_id: this.active_id})
            this.scrollToCard();
        },
        delete_array(){
            this.$emit('onDeleteArrayUpdate', this.delete_array);
        },
        changes_made_flag(state){
            // set table changes state
            this.setEditingState({
                uid: this.uid,
                state: { changes_made: state, }
            });
        },

    },
    methods:{
        handleShowGuideline(){
            this.setSelectedGuidelineID({
                type: this.guideline_key
            });
        },
        expandCardDetails(){
            this.updateCardExpandedState(this.uid);
        },
        debugAction(){
            console.log(this.data);
        },
        ...guidelineMethods,
        ...webixTable,
        handleNoteSave(event_data){
            const item_id = event_data.id;
            const note_text = event_data.notes;

            let item = window.webix.$$(this.uid).getItem(item_id);
            console.log('TIEM TO SAVE', item, event_data);
            item.notes = note_text;
            window.webix.$$(this.uid).updateItem(item_id, item);
            this.$nextTick(()=>{
                this.saveTable();
            })
        },
        showNoteModal(event_data){
            this.note_modal_payload = event_data;

            this.show_note_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('tableNotesModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "tableNotesModal") {
                        this.show_note_modal = false;
                        this.note_modal_payload = {
                            id: '',
                            notes: '',
                            title: '',
                        }
                    }
                });
            })
        },
        getWidthsFromStorage(){
            //return table state from local storage or return empty object as fallback
            return window.localStorage.table_widths != undefined ?
                JSON.parse(window.localStorage.table_widths) : {};
        },
        saveColumnWidthAdjustment(column_widths){
            //get widths from local storage
            let table_widths = this.getWidthsFromStorage()
            table_widths[this.uid] = column_widths;

            //* save updated object back to local storage
            window.localStorage.table_widths = JSON.stringify(table_widths);
        },
        onDataStructureUpdate(table){
            //get table instance
            //let table = this.getTable();
            //* continue if table != undefined (eg table is rendered)
            if(!table){ return; }
                
            this.freezeDivHeight();     //lock div height to prevent major ui jumps

            if(this.treetable){
                // handle treetable actions
                this.restoreTableTree();
            }
            else{
                // handle normal table
                this.restoreTable();
            }
            if(this.table_mode == 'edit'){
                //table.blockEvent();
                this.restoreColumnWidthState();
            }
            if(this.table_mode == 'view'){
                this.restoreColumnSort();
            }
            this.triggerAdjustColumnHeight(6);
        },restoreColumnWidthState2(){
            let table = this.getTable();
            let has_table_store_state = this.table_states[this.uid] != undefined;
            if(has_table_store_state && this.table_states[this.uid].column_widths != undefined){
                table.setState({
                    size: this.table_states[this.uid].column_widths,
                })
                table.refresh();
                //this.$nextTick(()=>{
                //    table.unblockEvent();
                //})
            }
        },
        restoreColumnSort(){
            if(!this.default_sort_column){
                return;
            }
            let table = this.getTable();
            if(table && table.getColumnIndex(this.default_sort_column) != -1){ //table.getColumnConfig(this.default_sort_column) != undefined
                // get state of sort, if nothing is found use the default, otherwise restore 
                let sort_state = table.getState().sort;
                if(sort_state != undefined){
                    table.sort(sort_state.id, sort_state.dir);
                    table.markSorting(sort_state.id, sort_state.dir);
                }
                else{
                    // default sort
                    table.sort(this.default_sort_column, "asc");
                    table.markSorting(this.default_sort_column, "asc");
                }
                
            }
        },
        restoreColumnWidthState: _.debounce(function(){
            if(this.table_mode != 'edit'){
                return;
            }
            let table = this.getTable();
            let has_table_store_state = this.table_states[this.uid] != undefined;
            if(has_table_store_state && this.table_states[this.uid].column_widths != undefined){
                table.setState({
                    size: this.table_states[this.uid].column_widths,
                })
                table.refresh();
                //this.$nextTick(()=>{
                //    table.unblockEvent();
                //})
            }
            //else{
                //this.$nextTick(()=>{
                //    table.unblockEvent();
                //})
            //}
        }, 350),
        restoreTableTree(){
            let table = this.getTable();
            if(this.last_tree_state != null){
                if(this.last_tree_state.open != undefined){ //* the open array will be undefined if no branches are expanded
                    this.block_set_state_event = true;
                    this.last_tree_state.open.forEach( item => {
                        if(table.exists(item)){
                            table.open(item);
                        }
                    })
                    this.block_set_state_event = false;
                }
                //restore selection
                this.restoreTable();
            }
            else{
                //restoe selection
                this.restoreTable()
            }
            this.restoreTreeExpandedState();
        },
        resetTableEditState(){
            //* reset tracking variables
            this.changes_made_flag = false;
            this.$emit('onDataUpdate', this.changes_made_flag);
            this.delete_array = [];
            //this.$emit('onDeleteArrayUpdate', this.delete_array);
            //* reset error messages
            this.error_messages = [];
        },
        restoreTable(){
            const table = this.getTable();
            if(!table){ return; }

            if(this.table_mode == 'view'){
                if(table.exists(this.active_id)){
                    if(this.treetable){
                        let item = table.getItem(this.active_id);
                        this.block_set_state_event = true;
                        while(item.$parent != 0){
                            //console.log('WHILE LOG 12')
                            table.open(item.$parent);
                            item = table.getItem(item.$parent);
                        }
                        this.block_set_state_event = true;
                    }
                    table.select(this.active_id);
                    this.$nextTick(()=>{
                        this.unfreezeDivHeight();
                    })
                }
                else{
                    //* if no match is found, select first row
                    if(this.data.length > 0 && this.prevent_first_row_select == false){
                        table.select(this.data[0].id);
                    }
                    else{
                        // if no data, emit null as a selection
                        this.$emit('selectedRowEvent', null)
                    }
                    this.$nextTick(()=>{
                        this.unfreezeDivHeight();
                    })
                }
            }
        },
        freezeDivHeight(){
            const table = this.getTable();
            if(this.$refs[this.getTableRefName] == undefined){
                return;
            }
            if(table == undefined){
                this.$refs[this.getTableRefName].style.height = `unset`
            }
            let parent_div = this.$refs[this.getTableRefName];
            if(table && parent_div){
                let height = parent_div.clientHeight;
                if(height > 10){
                    this.$refs[this.getTableRefName].style.height = `${height}px`
                }
            }
        },
        unfreezeDivHeight(){
            const table = this.getTable();
            if(this.$refs[this.getTableRefName] == undefined){
                return;
            }
            if(table == undefined){
                this.$refs[this.getTableRefName].style.height = `unset`
            }
            let parent_div = this.$refs[this.getTableRefName];
            //* only freeze the height id the div and table are in DOM
            if(table && parent_div){
                this.$refs[this.getTableRefName].style.height = `unset`
            }
        },
        getTable(){
            //return webix table instance
            return window.webix.$$(this.uid);
        },
        getTableBool(){
            //return webix table instance
            return window.webix.$$(this.uid) != undefined ? true : false;
        },
        //getTableRefName(){
        //    return `table-${this.uid}`;
        //},
        updateCopyListSelection(){
            //* Update the flag for copying items
            const table = this.getTable();
            if(table){
                let data = table.serialize();
                data = data.filter( item => item.checkbox_selection == 1);
                this.has_copy_items_selected = data.length;
            }
        },
        showToast(message, type){
            Swal.fire({
                icon: type,
                title: message,
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 4500,
                timerProgressBar: true,
            })
        },
        insertIntoDuplicationArray(item_id){
            this.items_to_duplicate.push(item_id)
        },
        removeFromDuplicationArray(item_id){
            let index = this.items_to_duplicate.findIndex( row => row == item_id);
            if(index != -1){
                this.items_to_duplicate.splice(index, 1)
            }
        },
        insertItemIntoClipboardArray(item){
            let included_index = _.findIndex(this.batch_copy_items, { id: item.id});
            if(included_index == -1){
                this.batch_copy_items.push(item);
            }
        },
        removeItemFromClipboard(item){
            let included_index = _.findIndex(this.batch_copy_items, { id: item.id});
            if(included_index != -1){
                this.batch_copy_items.splice(included_index, 1);
            }
        },
        clearBatchClipboard(){
            // passing in data = null will clear the clipboard in the vuex store
            let payload = {
                uid: this.uid,
                data: null,
            };
            // passing null data removes the current table uid clipboard
            this.insertIntoBatchClipboard(payload);
        },
        clearDuplicationClipboard(){
            // passing in data = null will clear the clipboard in the vuex store
            let payload = {
                uid: this.uid,
                data: null,
            };
            // passing null data removes the current table uid clipboard
            this.insertIntoDuplicationClipboard(payload);
        },
        getBatchCopyButtonText(){
            if(this.batch_copy_items.length == 1){
                return 'Copy 1 selected item'
            }
            else{
                return `Copy ${this.batch_copy_items.length} selected items`
            }
        },
        resetBatchClipboard(){
            let table = this.getTable();
            //table.refresh();
            this.batch_copy_items.forEach( row =>{
                let item = table.getItem(row.id);
                item.is_in_clipboard = false;
                table.updateItem(item.id, item);
            })
            this.first_batch_copy_item = null;
            this.batch_copy_items = [];
        },
        resetDuplicatedItems(){
            let table = this.getTable();
            //table.refresh();
            this.items_to_duplicate.forEach( id =>{
                let item = table.getItem(id);
                item.is_in_clipboard = false;
                table.updateItem(id, item);
            })
            this.first_batch_copy_item = null;
            this.batch_copy_items = [];
        },
        batchCopyItems(){
            let payload = {
                uid: this.uid,
                data: this.batch_copy_items,
            };
            this.insertIntoBatchClipboard(payload);
            this.resetBatchClipboard();
            this.enabled_batch_copy_flag = false;
            this.enabled_duplicate_copy_flag = false;
            // return table to view mode
            this.table_mode = 'view'
        },
        duplicateItems(){
            let payload = {
                uid: this.uid,
                data: this.items_to_duplicate,
            };
            this.insertIntoDuplicationClipboard(payload);
            this.resetDuplicatedItems();
            this.enabled_duplicate_copy_flag = false;
            // return table to view mode
            this.table_mode = 'view'
        },
        insertDuplicatedItems(target_id){
            let payload = {
                table_uid: this.uid,
                target_id: target_id
            }
            this.$emit('handleDuplicateionItemsInsert', payload);
        },
        //* table actions button start
        canShow(type){
            if(this.table_modes.includes(type)){
                return true;
            }
            else{
                return false;
            }
        },
        toggleSearch(){
            //* if table mode is card, then set the mode to view mode and then show search in header
            let has_valid_mode = ['view', 'edit', 'delete'].includes(this.table_mode);

            if(has_valid_mode == false){
                this.setMode('view');
            }

            this.setEditingState({
                uid: this.uid,
                state:{
                    // flip the toggle state
                    show_datatable_filters : !this.table_states[this.uid].show_datatable_filters
                }
            })

            //if search was hidden, clear and reset filter
            if(this.table_states[this.uid].show_datatable_filters == false){
                let table = this.getTable();
                if(table){
                    table.filter('');
                }
                this.search_text = '';
            }
        },
        showOtherMode(){
            this.delete_array = [];
            this.changes_made_flag = false;
            this.updated_rows = [],
            this.table_mode = 'other';
            this.pretty_mode = false;
        },
        showCardView(){
            this.delete_array = [];
            this.changes_made_flag = false;
            this.updated_rows = [],
            this.table_mode = 'view';
            this.pretty_mode = true;
            //this.pretty_mode = !this.pretty_mode;
            //this.setMode('view', true);
        },
        setMode(mode, block_pretty_toggle = false){
            //check if pretty_mode is enabled, so reset it
            if(block_pretty_toggle == false && this.pretty_mode){
                this.pretty_mode = false;
                let table = this.getTable()
                if(table){
                    this.$nextTick(()=>{
                        table.refresh();
                    })
                }
            }

            if(this.checkForPermissions() == false){
                return;
            }

            if(this.table_mode === 'edit' && this.changes_made_flag){
                this.showDiscardPrompt(mode);
                return;
            }

            if(mode == 'edit' && this.itCheck){
                //* first check if the current table is in edit mode -> set it back to view mode
                let table_id = _.findKey(this.table_states, { is_editing_table : true })
                if(table_id == this.uid){
                    this.setMode('view');
                    return;
                }
                let table_name = this.returnTableNameForUID(table_id, this.isCompanyLevel);
                Swal.fire({
                    title: `${table_name} is currently in edit mode.`,
                    text: `Save or Discard changes before continuing`,
                    icon: "info",
                })
                //Swal.fire("Another table is currently in edit mode.");
                return;
            }

            if(mode !== this.table_mode){
                this.table_mode = mode;
            }
            else if(mode !== 'view'){
                let selectedBtn =  `${mode}Button`;
                this.$refs[selectedBtn].blur();
                this.$refs.viewButton.click();
            }
        },
        showDiscardPrompt(type = this.enable_pretty_mode ? 'card' : 'view'){
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    if(type == 'card'){
                        //this.showCardView();
                        this.table_mode = 'view';
                        this.resetTableEditState();
                        let table = this.getTable();
                        table.parse(this.table_data);
                    }
                    else{
                        this.table_mode = type;
                    }
                }
            });
        },
        //* end
        msg(text){
            if(this.uid === 'objectivesTable'){
                console.log(text)
            }
        },
        //* set the row id into a webix store - used in computed properties
        //* where different options are required based on level id
        setStoreVariable(id){
            this.setLastEditorID(id)
        },
        allowValidationMessage(){
            this.show_error_msg = true;
        },

        //* update the table's current mode
        setTableMode(mode, old_mode = false){
            if(old_mode == "delete"){
                //! should actulay only loop through the selected items
                let table = this.getTable();
                table.eachRow((id)=>{
                    let item = table.getItem(id);
                    if(item.checkbox_selection == 1){
                        item.checkbox_selection = 0;
                        table.updateItem(item.id, item);
                    }
                })
            }
            if((mode == 'edit' || mode == 'delete') && this.checkForPermissions() == false){
                return;
            }
            this.freezeDivHeight();
            
            this.$emit('updateViewMode', mode)
            this.is_building_table = true;
            
            setTimeout(()=>{
                //eslint-disable-next-line
                let table = this.getTable();
                let table_pager_enabled = table && this.hasPagerProp && this.pager_position != null;
                if(table_pager_enabled){
                    //if(this.uid == 'productAndService')
                    //table.setPage(this.pager_position);
                    table.setPage(this.pager_position);
                }
                switch(mode){
                    case 'edit':{
                        this.enableEditMode();
                        this.setEditingState({
                            uid: this.uid,
                            state: { is_editing_table: true, }
                        });
                        break;
                    }
                    case 'delete':{
                        
                        this.enableDeleteMode();
                        this.setEditingState({
                            uid: this.uid,
                            state: { is_editing_table: false, }
                        });
                        break;
                    }
                    default :{
                        this.enableViewMode();
                        this.setEditingState({
                            uid: this.uid,
                            state: { is_editing_table: false, }
                        });
                        break;
                    }
                }
            }, 100)
        },

        //* restores the expanded treetable nodes before the table was switched to edit mode
        restoreTreeState: _.debounce(function(){
            let table = window.webix.$$(this.uid);
            if(table && table.exists(this.active_id) == false){
                // select first available row or return null when no rows are found
                if(this.table_data.length > 1){
                    this.$emit('selectedRowEvent', this.table_data[0]);
                }
                else{
                    this.$emit('selectedRowEvent', null)
                }
                
            }
            if(this.last_tree_state != null){
                //* HERE WE SET THE LAST SELECTED ID -> using table.open triggers the getState while (upon table reload there is no selection) 
                //eslint-disable-next-line
                let last_selected_id = null;
                if(this.last_tree_state.select.length > 0){
                    if(this.table_mode == 'edit' || this.table_mode == 'view'){
                        last_selected_id = this.last_tree_state.select[0].id;
                    }
                }
                
                if(this.last_tree_state.open != undefined){ //* the open array will be undefined if no branches are expanded
                    this.block_set_state_event = true;
                    this.last_tree_state.open.forEach( item => {
                        if(table.exists(item)){
                            table.open(item);
                        }
                    })
                    this.block_set_state_event = false;
                }
                

                /*if(last_selected_id != null){
                    table.select(last_selected_id);
                }*/
                if(this.active_id != -1 && this.table_mode === 'view' && table.exists(this.active_id)){
                    table.select(this.active_id);
                }
                if(this.active_id == -1 && this.table_mode === 'view' && this.table_data.length > 0){
                    let id = this.table_data[0].id;
                    if(window.webix.$$(this.uid).exists(id)){
                        window.webix.$$(this.uid).select(id);
                    }
                }
                
            }
            else{
                if(this.active_id != -1 && this.table_mode === 'view' && table.exists(this.active_id)){
                    table.select(this.active_id);
                }
                if(this.active_id == -1 && this.table_mode === 'view' && this.table_data.length > 0){
                    let id = this.table_data[0].id;
                    if(window.webix.$$(this.uid).exists(id)){
                        window.webix.$$(this.uid).select(id);
                    }
                }
            }
            //window.webix.$$(this.uid).setState(this.last_tree_state);
        }, 200),

        emitRowSelection(item){
            //removed has mounted check
            if(window.webix.$$(this.uid) != undefined){
                this.$emit('selectedRowEvent', item)
            }
            
        },

        //* Force webix to process new data
        updateTableData(){

            return new Promise( resolve => {
                let table_pager = window.webix.$$(this.uid+'pager')
                if(table_pager != undefined){
                    table_pager.refresh();
                }
                this.$nextTick(()=>{
                    //if(this.hasMounted){ this.restoreSelection(4); }
                    //* resolve after ui updates
                    resolve();
                })
            })
        },

        //* Modify the columns of a table based on the selected view mode
        //initColumns(prevent_reselect = false){
        initColumns(){
            let el = this;
            return new Promise( resolve => {
                //* Process columns
                let columns = [];
                if(el.table_mode == 'view' && el.simple_columns != null){
                    columns = _.cloneDeep(this.simple_columns)
                }
                else{
                    columns = _.cloneDeep(this.columns);
                }

                columns.forEach( item => {
                    if(item.readonly == true){
                        item.css = 'read-only-column'
                    }
                    if(item.visibility){
                        if(!item.visibility.find( e => e === this.table_mode)){
                            item['hidden'] = true;
                        }
                    }
                })

                if(this.table_mode === 'delete'){
                    //* adds a checkbox to all rows to mark rows for deletion
                    let index = _.findIndex(columns, {id: 'checkbox_selection'});
                    if(index == -1){
                        columns.unshift({
                            header: 'Delete',
                            id: 'checkbox_selection',
                            checkValue:1, uncheckValue: 0,
                            template:"{common.checkbox()}",
                            //template: (obj, common) => {
                            //    if(el.delete_requirement == null){
                            //        return common.checkbox(obj, common, 1, 0);
                            //    }
                            //    else{
                            //        let check_var = el.delete_requirement.variable_name;
                            //        if(el.delete_requirement.id == obj[check_var]){
                            //            return common.checkbox(obj, common, 1, 0);
                            //            //el.showToast("Only the document owner can delete this document", 'error')
                            //        }
                            //    }
                            //    return '';
                            //},
                            width:80
                        })
                    }
                }
                else if(this.table_mode === 'drag'){
                    console.log('No drag columns')
                }
                else if(this.table_mode === 'edit'){
                    //* hide non editable columns if 'only_editable_columns' prop is true
                    //TODO - replace this with onlyInEdit property in each column
                    if(this.only_editable_columns){
                        let filtered_columns = [];
                        columns.forEach( col => {
                            if(col.editor != undefined || this.ignore_edit_only_rules.includes(col.id)){
                                filtered_columns.push(col);
                            }
                        })
                        columns = _.cloneDeep(filtered_columns);
                    }
                    
                    //* insert column to clear newly changed rows
                    //! can be hidden if the hide_clear_column prop is set to TRUE
                    if(this.hide_clear_column == false && this.no_extra_rows == false){
                        let index = _.findIndex(columns, {id: 'remove_new_row'});
                        if(index == -1){
                            columns.push({
                                header: '',
                                id: 'remove_new_row',
                                //template:"<button><i class='fa fas-trash'><i/><button/>",
                                width: 30,
                                template:function(obj){
                                    let html = '';
                                    if(obj.is_new != undefined){
                                        if(obj.is_new == true){
                                            //html = "<div class='webix_el_button'><button style='width: 100%;' class='unlink_button'> Unlink </button></div>"
                                            html = `<div class='clear_new_row'>
                                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="me-1 svg-inline--fa fa-trash"><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" class=""></path></svg>
                                                </div>`
                                            //html = obj.is_empty
                                        }
                                    }
                                    return html;
                                },
                            })
                        }
                        // if(this.re_order){
                        //     //* adds a delete icon for all newly created rows (unsaved rows)
                        //     index = _.findIndex(columns, {id: 'unlink_button'});
                        //     if(index == -1){
                        //         columns.unshift({
                        //             header: 'Row Order',
                        //             id: 'unlink_selection',
                        //             template:function(obj){
                        //                 let html = '';
                        //                 if(obj.is_empty == undefined){
                        //                     html = `<span>
                        //                                 <i class='action_icon move-item-up fas fa-arrow-up'></i>
                        //                                 <i class='action_icon move-item-down fas fa-arrow-down'></i>
                        //                             </span>`
                        //                 }
                        //                 return html;
                        //             },
                        //             width:80
                        //         })
                        //     }
                        // }
                    }
                }
                else{
                    //* for VIEW mode

                    //remove checkbox column
                    let index1 = _.findIndex(columns, {id: 'checkbox_selection'});
                    if(index1 != -1){
                        delete columns[index1];
                    }

                    //remove unlink button column
                    let index2 = _.findIndex(columns, {id: 'unlink_button'});
                    if(index2 != -1){
                        delete columns[index2];
                    }

                    //remove clear new row column
                    let index3 = _.findIndex(columns, {id: 'remove_new_row'});
                    if(index3 != -1){
                        delete columns[index3];
                    }
                }

                //* Optional Prop --
                //* hide all columns that have the 'compact' property added
                window.webix.$$(this.uid).refreshColumns(columns);
                this.restoreColumnSort()
                resolve();
            })
        },
        restoreSelection(){
            if(this.table_mode == 'delete'){
                return;
            }
            if(this.prevent_first_row_select == false){
                if(this.treetable){
                    if(this.table_data.length > 0){
                        if(this.last_tree_state != null){
                            if(window.webix.$$(this.uid) != undefined){
                                //this.$nextTick(()=>{
                                    this.restoreTreeState();
                                //})
                            }
                        }

                        else if(window.webix.$$(this.uid) != undefined){
                            if(window.webix.$$(this.uid).exists(this.active_id)){
                                window.webix.$$(this.uid).select(this.active_id);
                                let table = window.webix.$$(this.uid);
                                this.block_set_state_event = true;
                                let item = table.getItem(this.active_id);
                                //* in webix root nodes have $parent = 0, then first child has $parent = 1 and so on
                                while(item.$parent != 0){
                                    /* open parent, then set current item */
                                    table.open(item.$parent);
                                    item = table.getItem(item.$parent);
                                }
                                this.block_set_state_event = false;
                            }
                            else{
                                //* else select first item
                                let id = this.table_data[0].id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    this.$nextTick(()=>{
                                        window.webix.$$(this.uid).select(id);
                                    })
                                    
                                }
                            }
                        }
                    }
                }
                else{
                    if(this.data.length > 0 ){
                        let index = _.findIndex(this.data, {id:this.active_id});
                        if(window.webix.$$(this.uid) != undefined){
                            if(index != -1){
                                //select valid item
                                let id = this.active_id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    window.webix.$$(this.uid).select(id);
                                }
                            }
                            else{
                                //else select forst item
                                let id = this.data[0].id;
                                if(window.webix.$$(this.uid).exists(id)){
                                    window.webix.$$(this.uid).select(id);
                                }
                            }
                        }
                    }
                }
            }
            else{
                if(this.data.length > 0 ){
                    let index = _.findIndex(this.data, {id:this.active_id});
                    if(window.webix.$$(this.uid) != undefined){
                        if(index != -1){
                            //select valid item
                            let id = this.active_id;
                            if(window.webix.$$(this.uid).exists(id)){
                                window.webix.$$(this.uid).select(id);
                            }
                        }
                    }
                }
            }
        },
        insertEmptyRows(rows = 5){// default of 5
            if(this.no_extra_rows == true){
                //* prop used to prevent extra rows
                return;
            }


            this.empty_row_ids = [];


            //* add new rows with a unique id and is_empty flag
            for(let i = 0; i < rows; i++){
                let row = {
                    id: `newRow-${window.webix.uid()}`,
                    is_empty: true,
                }
                if(this.treetable){
                    row['data'] = [];
                }
                //eslint-disable-next-line
                this.empty_row_ids.push(row.id);
                window.webix.$$(this.uid).add(row);
            }
        },
        enableEditMode(){
            if(window.webix.$$(this.uid) == undefined){ return; }
                //* object of last selected row/col
                //const table_selection = window.webix.$$(this.uid).getSelectedId();
                //* used for opening an editor for the first cell
                //! temp removed
                // let table_selection = this.last_selected_item;

                // let pager_position = null;
                // if(this.hasPagerProp){
                //    pager_position = window.webix.$$(this.uid).getPage();
                // }
                
                this.changes_made_flag = false;
                //window.webix.$$(this.uid).clearSelection();
                window.webix.$$(this.uid).define({ editable: true });
                ////window.webix.$$(this.uid).define({ multiselect: true });
                window.webix.$$(this.uid).define({ rules: this.validation });
                window.webix.$$(this.uid).define({ fillspace: false });
                window.webix.$$(this.uid).define({ scroll: true });
                window.webix.$$(this.uid).define({ scrollX: true });
                if(this.has_split_column){
                    window.webix.$$(this.uid).define({ leftSplit: 2 });
                }
                window.webix.$$(this.uid).define({ resizeColumn: {headerOnly:true} });
                //resizeColumn: {headerOnly:true},
                if(this.ui.drag != undefined){
                    //! OLD UNUSED FEATURE
                    //TODO
                    delete this.ui.drag;
                }

                //window.webix.$$(this.uid).refresh();

                //* setup sticky mode for edit view
                //this.ui.fillspace = false;
                //this.ui.scroll = true;
                //this.ui.leftSplit = 2;

                //this.$nextTick(()=>{
                    this.refreshConfig(4)
                    .then(()=>{
                        //* Add a few empty rows to add new items
                        if(this.hasPagerProp == false){
                            //*only add if no pager
                            //*if there is a pager then the computed property will auto fill empty rows
                            this.insertEmptyRows();

                            //* here we can focus on the last selected row/col
                            //! Need to check if the selected column is an editable column
                            //! If it isn't then find the first editable column from the columns array
                            //this.$nextTick(()=>{
                                if(this.last_tree_state != null && this.last_tree_state.open    ){
                                    if(window.webix.$$(this.uid) != undefined){
                                        //this.$nextTick(()=>{
                                            this.last_tree_state.open.forEach( id => {
                                                if(window.webix.$$(this.uid).exists(id)){
                                                    window.webix.$$(this.uid).open(id);
                                                }
                                            })
                                        //})
                                    }
                                }
                                
                            //});
                        }
                        this.unfreezeDivHeight();
                    })
                //})
        },
        enableDeleteMode(){
            window.webix.$$(this.uid).clearSelection();
            window.webix.$$(this.uid).define({ editable: false });
            window.webix.$$(this.uid).define({ multiselect: false });
            window.webix.$$(this.uid).define({ select: false });
            window.webix.$$(this.uid).define({ rules: {} });
            window.webix.$$(this.uid).define({ drag: false });

            window.webix.$$(this.uid).define({ fillspace: false });
            window.webix.$$(this.uid).define({ scroll: true });
            window.webix.$$(this.uid).define({ scrollX: true });
            if(this.has_split_column){
                window.webix.$$(this.uid).define({ leftSplit: 0 });
            }
            //if(this.has_split_column){
            //    window.webix.$$(this.uid).define({ leftSplit: 2 });
            //}
            
            window.webix.$$(this.uid).define({ resizeColumn: {headerOnly:true} });
            window.webix.$$(this.uid).clearSelection();
            if(this.ui.drag != undefined){
                delete this.ui.drag;
            }
            this.changes_made_flag = false;
            //* Redraw the table with updated config
            this.$nextTick(()=>{
                this.refreshConfig(3)
                .then(()=>{
                    //this.updateTableData();
                    if(this.treetable){
                        window.webix.$$(this.uid).openAll();
                    }
                    this.unfreezeDivHeight();
                })
            })
        },
        enableViewMode(){
            let table = this.getTable();
            if(table == undefined){
                return;
            }
            
            /*if(this.show_simple_table){
                //* set config options for view mode
                if(window.webix.$$(this.uid).config.leftSplit){
                    delete window.webix.$$(this.uid).config.leftSplit;
                }
                window.webix.$$(this.uid).config.fillspace = true;
                window.webix.$$(this.uid).config.scroll = false;
                window.webix.$$(this.uid).config.scrollY = false;
                //window.webix.$$(this.uid).define({ editable: false });
                //window.webix.$$(this.uid).define({ multiselect: false });
                //window.webix.$$(this.uid).define({ rules: {} });
                //window.webix.$$(this.uid).define({ drag: false });
                //window.webix.$$(this.uid).define({ fillspace: false });
                //window.webix.$$(this.uid).define({ scroll: false });
                //window.webix.$$(this.uid).define({ scrollX: false });
                
                //window.webix.$$(this.uid).define({ leftSplit: 0 });
                //window.webix.$$(this.uid).define({ resizeColumn: false });
                //* set table config options for view mode
                if(this.ui.drag != undefined){
                    delete this.ui.drag;
                }
                if(this.ui.leftSplit != undefined){
                    delete this.ui.leftSplit;
                }
                if(this.ui.resizeColumn != undefined){
                    delete this.ui.resizeColumn;
                }
            }*/
            //else{
                table.define({ editable: true });
                //window.webix.$$(this.uid).define({ multiselect: true });
                table.define({ rules: this.validation });
                table.define({ fillspace: false });
                table.define({ scroll: true });
                table.define({ scrollX: true });
                if(this.has_split_column){
                    table.define({ leftSplit: 2 });
                }
                table.define({ resizeColumn: {headerOnly:true} });
            //}
            table.refresh();
            table.refreshColumns();
            

            this.changes_made_flag = false;
            this.enabled_batch_copy_flag = false;
            this.resetBatchClipboard();

            //* Redraw the table with updated config
            this.$nextTick(()=>{
                this.refreshConfig(2)
                .then(()=>{
                    this.empty_row_ids.forEach( id => {
                        if(table.exists(id)){
                            table.remove(id);
                        }
                    })
                    this.unfreezeDivHeight();
                    //this.updateTableData();
                    //*
                })
            })

        },
        refreshConfig(debug, prevent_reselect = false){
            //* reset the has_copy_items_selected
            this.has_copy_items_selected = 0;

            return new Promise(resolve => {
                if(window.webix.$$(this.uid) != undefined){
                    //window.webix.$$(this.uid).clearAll();
                    //* set columns based on table_mode
                    this.initColumns(prevent_reselect)
                    .then(()=>{
                        //this.$nextTick(()=>{
                            //* refresh table and pager
                            if(window.webix.$$(this.uid) != undefined){
                                window.webix.$$(this.uid).refresh();
                                if(window.webix.$$(this.uid+'pager') != undefined){
                                    window.webix.$$(this.uid+'pager').refresh();
                                }
                                //this.triggerAdjustColumnHeight(1);
                                // trigger column autoheight
                                
                            }

                            this.updateTableData();
                            resolve();
                        //})
                    });
                }
            });
        },
        processOrderArray(parent_id = null){
            let table = window.webix.$$(this.uid);
            let data = null;
            if(parent_id != null){
                data = table.serialize(parent_id);
            }
            else{
                data = table.serialize();
                data = data.filter( row => row.is_empty == undefined);
            }
            let sortData = [];
            data.forEach( data => {
                sortData.push({
                    id: data.id,
                    item_order: this.treetable ? (table.getBranchIndex(data.id) + 1) : (table.getIndexById(data.id) + 1)
                })
            });
            this.$emit('updatedOrderEvent', sortData);
        },
        destroyTable(){
            this.setEditingState({
                uid: this.uid,
                state: null,
            });
            if(window.webix.$$(this.uid+'pager') != undefined){
                //eslint-disable-next-line
                $$(this.uid+'pager').destructor();
            }
            //this.$nextTick(()=>{
                if(window.webix.$$(this.context_menu_id) != undefined){
                    window.webix.$$(this.context_menu_id).destructor();
                }
                if(window.webix.$$(this.uid) != undefined){
                    window.webix.$$(this.uid).destructor();
                }

                
            //})
        },
        scrollToCard(id = this.active_id){
            if(id != null){
                return;
            }
            let element = document.getElementById(id);
            if (element) {
                this.$nextTick(()=>{
                    element.scrollIntoView({ behavior: 'instant', block: "nearest", inline: "nearest" });
                })
            }
        },
        debugMethod(){
            //this.is_loading = true;
            this.scrollToCard();
            setTimeout(()=>{
                //this.is_loading = false;
            }, 500)
        },
        closeWebixEditor(){
            window.webix.$$(this.uid).editStop();
        },
        triggerAdjustColumnHeight(){
            if(this.column_autoheight_key){
                // check if column exists
                setTimeout(()=> {
                    if(window.webix.$$(this.uid).getColumnIndex(this.column_autoheight_key) != -1){
                    console.log("running here");
                    window.webix.$$(this.uid).adjustRowHeight(this.column_autoheight_key, false);
                    }
                }, 500)
                
            }
        },
        resizeTable(){
            if(window.webix.$$(this.uid) != undefined){
                window.webix.$$(this.uid).refresh();
            }
        },
        configurePager(){
            //* set up webix config with props from parent
            if(this.hasPagerProp){
                let ctx = this;
                this.ui['pager'] = {
                    container: this.uid + 'pager',
                    id: this.uid + 'pager',
                    size: this.pager_config,
                    group: 5,
                    template: `{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}`,
                    on:{
                        onItemClick(){
                            if(ctx.hasPagerProp){
                                ctx.$nextTick(()=>{
                                    ctx.pager_position = ctx.getTable().getPage();
                                })
                                
                            }
                        },
                    }
                }
            }
        },


        //* use ref to call this method
        saveTable(){
            if(this.updated_rows.length == 0){
                alert('No rows updated')
                return;
            }
            let table = window.webix.$$(this.uid);
            table.editStop();

            if(table.validate() == false){
                this.$swal.fire('Table has invalid rows');
                return;
            }
            let all_data = table.serialize(null, true);

            if(this.treetable){
                //* flatten nested treetable items
                const flatten = (members) => {
                    let data = [];
                    return members.map(m => {
                        if (m.data && m.data.length) {
                            data = [...data, ...m.data];
                        }
                        return m;
                    }).concat(data.length ? flatten(data) : data);
                };
                all_data = flatten(all_data);
            }
            //* remove empty rows
            let data = all_data.filter( item => item.is_empty == undefined);

            //* only get edited rows
            data = data.filter( item => this.updated_rows.includes(Number(item.id)))
            
            this.$emit('saveTable', data);
        },
        deleteRows(){
            Swal.fire({
                title: "Delete selected items?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.$emit('deleteRows', this.delete_array)
                }
            });
        },


        checkForPermissions(){
            if(this.required_permissions.length == 0){
                return true;
            }
            let has_permission = false;

            this.required_permissions.forEach( perm => {
                console.log(perm);
                if(this.$hasPerm(perm)){
                    has_permission = true;
                }
            });
            if(!has_permission){
                Swal.fire({
                    icon:'warning',
                    title:"Not available",
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            }
            return has_permission;
        },
        isManagerCheck(user_id, item_owner_id){
            let item_owner = this.all_users.find( user => {
                return user.id == item_owner_id;
            });

            return user_id == item_owner.report_to_user_id;
        },

        preserveTreeExpandedState(){
            let table = this.getTable();
            if(table){
                let state = table.getState();
                this.saveTreetableState({
                    uid: this.uid,
                    row_state: state.open,  // get ids of open rows
                })
            }
        },
        //eslint-disable-next-line
        restoreTreeExpandedState(){
            let table = this.getTable();
            if(!table){ return; }
            
            if(this.treetable_states[this.uid] != undefined){
                this.block_set_state_event = true;
                this.treetable_states[this.uid].forEach( row_id => {
                    if(table.exists(row_id)){
                        table.open(row_id);
                    }
                })
                this.block_set_state_event = false;
                this.$nextTick(()=>{
                    this.unfreezeDivHeight();
                })
            }
        },
        refreshVisibleColumns(){
            let table = this.getTable();
            if(table){
                if(this.table_mode == 'view' && this.simple_columns != null){
                    table.refreshColumns(this.simple_columns);
                }
                else{
                    table.refreshColumns(this.columns);
                }
            }
        },
        handleForceClearTableData(table_uid){
            if(this.uid == table_uid){
                if(this.enable_pretty_mode){
                    this.showDiscardPrompt('card');
                }
                else{
                    this.showDiscardPrompt('view');
                }
                
            }
        }
    },
    beforeMount(){
        //* misc table config
        this.configurePager();
        this.ui.id = this.uid; //* assign the given uid from prop

        //* Table has 2 modes -- tree and normal.
        if(this.treetable == false){
            this.ui.view = 'datatable';
        }

        // set extra config value for adjustRowHeight prop
        if(this.column_autoheight_key){
            this.ui['fixedRowHeight'] = false;
        }
        
        //* if the sticky_columns prop is > 0 then the 'fillspace:true' property must be false
        if(this.sticky_columns > 0){
            this.ui.fillspace = false;
            this.ui.scroll = true;
            this.ui.leftSplit = this.sticky_columns;
        }

        //* merge custom click actions
        if(this.button_methods != false){
            this.ui.onClick = {
                ...this.ui.onClick,
                ...this.button_methods
            }
        }

        //* defines rules for if a specified column should allow it's editor to show
        if(this.custom_editor_rules != false){
            /*this.ui.on = {
                ...this.ui.on,
                ...this.custom_editor_rules,
            }*/
            this.ui.on['onBeforeEditStart'] = this.custom_editor_rules
        }

        //* display the table element so that it's available for the webix.$$ selector
        this.show_table = true;

        //* Table pre config finished, now render table
        this.$nextTick(()=>{
            this.setTableMode(this.table_mode);
            //* Configure native resize event
            let table_uid = this.uid;
            let el = this;
            this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
                let table = window.webix.$$(table_uid);
                if(table){
                    table.refresh();
                    el.triggerAdjustColumnHeight(2);
                }
            }, 500));
        });
    },
    //beforeMount(){
        
    //},
    mounted(){
        // if(this.default_table_mode != 'view'){
        
        //     this.table_mode = this.default_table_mode
        // }
        const table_widths = this.getWidthsFromStorage();
        let initial_state = {
            show_datatable_filters: false,
            is_editing_table: false,
            table_uid: this.uid,
            has_nested_data: this.treetable,
        }
        if(table_widths[this.uid]){
            initial_state['column_widths'] = table_widths[this.uid];
        }
        // init table state to vuex
        this.setEditingState({
            uid: this.uid,
            state: initial_state,
        });

        if(this.enable_pretty_mode){
            this.pretty_mode = true;
        }
        this.updateActiveCardID({uid: this.uid, item_id: this.active_id})

        //this.$eventHub.$on('clearTableChangesState', this.handleForceClearTableData)
    },

    beforeDestroy(){
        //this.$eventHub.$off('clearTableChangesState')
        // if table is a tree, we need to save the expanded columns to vuex store
        //*remove resize event
        window.webix.eventRemove(this.webix_resize_event);
        //* prevent non unique id console error when hot refreshing
        this.destroyTable();

        
        //this.$eventHub.$off('windowResizedEvent');
        //this.$eventHub.$off('windowResizedEvent');
    }
}
</script>

<style>


.widthClass{
    transition: all 0.2s linear;
}
.no-focus:focus{
    outline: 0 !important;
} 
.no-focus   {
    
    box-shadow: none !important;
}

.btn-info.no-focus:active{
    background-color: #50a5f1 !important;
}
.btn-primary.no-focus:active{
    background-color: #556ee6 !important;
}
.btn-success.no-focus:active{
    background-color: #34c38f !important;
}
.btn-danger.no-focus:active{
    background-color: #f46a6a !important;
}

/* CARD HEADER END*/




/* WEBIX STYLES */

    .textClass{ border: 0;}
    .webix_cell .webix_row_select{
        color: dodgerblue !important;
    }

    .tableEditMode .webix_hcell  { background: rgb(162, 208, 255) !important; }
    .tableEditMode .webix_dtable  {
        /*border-color: dodgerblue;*/
        /*border: 0;
        outline: 1px solid dodgerblue;
        outline-offset: 0px;*/
        -webkit-box-shadow: 0px 0px 3px 1px dodgerblue; 
        box-shadow: 0px 0px 3px 1px dodgerblue;
    }
    .tableViewMode .webix_dtable  {
        /*border-color: dodgerblue;*/
        /*border: 0;
        outline: 1px solid #afafaf;
        outline-offset: 0px;*/
        -webkit-box-shadow: 0px 0px 0px 1px #afafaf; 
        box-shadow: 0px 0px 3px 0px #afafaf;
    }

    .tableViewModeMultiselect .webix_dtable{
        -webkit-box-shadow: 0px 0px 0px 1px #afafaf; 
        box-shadow: 0px 0px 3px 1px #0baa46;
    }

    .tableDeleteMode .webix_hcell  { background: rgb(255, 162, 162) !important; }
    .tableDeleteMode .webix_dtable  {
        /*border-color: red; */
        /*border: 0;
        outline: 1px solid red;
        outline-offset: 0px;*/
        -webkit-box-shadow: 0px 0px 3px 1px red; 
        box-shadow: 0px 0px 3px 1px red;
    }

    .tableDragMode .webix_hcell  { background: rgb(195, 255, 193) !important; }
    .tableDragMode .webix_dtable  { border-color: var(--bs-success); }

    .webix_drag_item{
        background-color: transparent !important;
        padding-left: 7px;
        padding-right: 7px;
        font-size: 13px;
        border: 1px solid grey;
        border-radius: 0.1rem;
        padding-bottom: 0px;
    }

    .is_copied_to_clipboard_style{
        background-color: aquamarine;
    }
    .new_row_style{
        background-color: rgb(217, 255, 217);
    }
    .delete_row_style{
        background-color: rgb(255, 162, 162);
    }

    a[webix_l_id=View]{
        color: var(--bs-primary) !important;
    }
    a[webix_l_id=Edit]{
        color: var(--bs-info) !important;
    }
    a[webix_l_id=Delete]{
        color: var(--bs-danger) !important;
    }
    a[webix_l_id=Drag]{
        color: var(--bs-success) !important;
    }

    .clear_new_row:hover>i{
        color: cornflowerblue;
    }
    .clear_new_row{
        cursor: pointer;
        width: 100% !important; 
    }

    .webix_invalid.webix_invalid_cell{
        background-color: #222;
        animation-name: color;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    .webix_pager{
        width: 100% !important;
    }
    @keyframes color {
      0% {
        background-color: #ffdedb;
      }
      /*25% {
        background-color: #ffb3ac;
      }*/
      50% {
        background-color: #ffa9a1;
      }
      100% {
        background-color: #ffdedb;
      }
    }

    .error-list{
        font-weight: bold;
        color: rgb(255, 62, 62)
    }
    .action_icon{
        text-align: center;
        width: 20px;
    }
    .action_icon:hover{
        cursor: pointer;
        color: dodgerblue;
    }

    .card-style-1{
        background: radial-gradient(circle, rgba(116,220,196,0.4) 37%, rgba(85,128,235,0.4) 100%);
    }
    .card-style-2{
        background: radial-gradient(circle, rgba(136,93,227,0.4) 36%, rgba(229,125,161,0.4) 100%);
    }
    .card-style-3{
        background: radial-gradient(circle, rgba(255,102,54,0.4) 36%, rgba(236,77,111,0.4) 100%);
    }
    .card-style-4{
        background: radial-gradient(circle, rgba(255,184,7,0.4) 36%, rgba(255,130,3,0.4) 100%);
    }

    /*.objective-card{
        background: rgb(0 255 170 / 50%);
        background: radial-gradient(circle, rgb(0 255 170 / 50%) 35%, rgb(170 255 170 / 49%) 100%);
    }*/

    .read-only-column{
        background: #f7f7f7;
        cursor: no-drop;
    }

    .make-circle{
        padding: 2px;
        width: 16px !important;
        height: 16px  !important;
        border: 1px solid black;
        border-radius: 2rem;
    }

    .fix-z-index button{
        z-index: 0 !important;
    }

    .hide-scroll-bar .webix_vscroll_body{
        height: 0px !important;
    }
    .hide-scroll-bar>.webix_view>.webix_ss_hscroll{
        height: 0px !important;
    }
    .hide-scroll-bar>.webix_view{
        height: 35px !important;
    }


    /* //* For normal buttons */
    .table-actions-btn-group>.btn-outline-light{
        color: #aaaaaa;
        border-color: #aaaaaa !important;
    }
    .table-actions-btn-group>.btn-outline-light:hover{
        color: #3f3f3f;
        /* border-color: #aaaaaa !important; */
    }

    /* //* For b dropdown buttons */
    .table-actions-btn-group>.dropdown>.btn-outline-light{
        color: #aaaaaa;
        border-color: #aaaaaa !important;
    }
    .table-actions-btn-group>.dropdown>.btn-outline-light:hover{
        color: #3f3f3f;
        /* border-color: #aaaaaa !important; */
    }
    .WebixTableComponent{

    }
</style>